import { storeActions } from "./storeSlice";

interface storeDetails {
  adType?: {
    _id: string,
    title: string,
  }[],
  brand?: {
    _id: string,
    title: string,
  }[],
  brandCollab?: {
    _id: string,
    title: string,
  }[],
  category?: {
    _id: string,
    title: string,
  }[],
  country?: {
    _id: string,
    title: string,
  }[],
  feature?: {
    _id: string,
    title: string,
  }[],
  keyword?: {
    _id: string,
    title: string,
  }[],
  language?: {
    _id: string,
    title: string,
  }[],
  orientation?: {
    _id: string,
    title: string,
  }[],
  region?: {
    _id: string,
    title: string,
  }[],
  stage?: {
    _id: string,
    title: string,
  }[],
  subBrand?: {
    _id: string,
    title: string,
  }[],
  year?: {
    _id: string,
    title: string,
  }[],
  creative?: {
    srno: number,
    id: string,
    name: string,
    img: string,
    videoLink: string,
    isPublished: boolean,
  }[]
  filters?: {
    adType?: string[],
    brand?: string[],
    brandCollab?: string[],
    category?: string[],
    country?: string[],
    feature?: string[],
    keyword?: string[],
    language?: string[],
    orientation?: string[],
    region?: string[],
    stage?: string[],
    subBrand?: string[],
    year?: string[],
  }[]
}

interface config {
  isSession: boolean;
}

const storeHandler = (storeDetails: storeDetails) => {
  return (dispatch: (arg0: { payload: any; type: "data/store"; }) => void) => {

    const {
      adType,
      brand,
      category,
      country,
      feature,
      keyword,
      language,
      orientation,
      region,
      stage,
      subBrand,
      creative,
      brandCollab,
      year,
      filters

    } = storeDetails;
    dispatch(
      storeActions.store({
        adType,
        brand,
        category,
        country,
        feature,
        keyword,
        language,
        orientation,
        region,
        stage,
        subBrand,
        creative,
        brandCollab,
        year,
        filters
      }));
  };
};

const storeCleanHandler = (storeDetails: storeDetails) => {
  return (dispatch: (arg0: { payload: any; type: "data/storeClean"; }) => void) => {

    const {
      adType,
      brand,
      category,
      country,
      feature,
      keyword,
      language,
      orientation,
      region,
      stage,
      subBrand,
      creative,
      brandCollab,
      year,
      filters
    } = storeDetails;

    dispatch(storeActions.storeClean({
      adType,
      brand,
      category,
      country,
      feature,
      keyword,
      language,
      orientation,
      region,
      stage,
      subBrand,
      creative,
      brandCollab,
      year,
      filters
    }));
  };
};

const storeCreativeCleanHandler = () => {
  return (dispatch: (arg0: { payload: undefined; type: "data/cleanCreative"; }) => void) => {
    dispatch(storeActions.cleanCreative());
  };
};

const logoutFilterHandler = () => {
  return (dispatch: (arg0: { payload: undefined; type: "data/logout"; }) => void) => {
    console.log("cleaned store");
    dispatch(storeActions.logout());
  };
};

export { storeHandler, storeCleanHandler, logoutFilterHandler, storeCreativeCleanHandler };
