import apiSlice from "../../features/apiSlice";
import { removeEmptyKeys } from "../../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBrandCollab: builder.query({
      query: (queries) => {
        return {
          url: '/brandCollab',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['BrandCollab']
    }),
    createBrandCollab: builder.mutation({
      query: (credentials) => ({
        url: "/brandCollab",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['BrandCollab']
    }),
    updateBrandCollab: builder.mutation({
      query: (credentials) => ({
        url: `/brandCollab/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags:['BrandCollab']
    }),    
    bulkUpdateBrandCollab: builder.mutation({
      query: (credentials) => ({
        url: `/brandCollab/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['BrandCollab']
    }),
    deleteBrandCollab: builder.mutation({
      query: (credentials) => ({
        url: `/brandCollab/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['BrandCollab']
    }),
    hardDeleteBrandCollab: builder.mutation({
      query: (credentials) => ({
        url: `/brandCollab/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['BrandCollab']
    }),
  }),
});

export const {
  useGetBrandCollabQuery,
  useCreateBrandCollabMutation,
  useUpdateBrandCollabMutation,
  useBulkUpdateBrandCollabMutation,
  useDeleteBrandCollabMutation,
  useHardDeleteBrandCollabMutation
} = authApiSlice;
