import AdminLayout from "../../../components/organisms/adminLayout";
import DataGridBox from "../../../components/organisms/dataGrid";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowModesModel,
  GridRowsProp,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import React, { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AdminPageFilters from "../../../components/organisms/adminPageFilters";
import { DeletePopup } from "../../../components/molecules/DeleteModal";
import snackbar from "../../../api/snackbar/useSnackbar";
import {
  useBulkUpdateUsersMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useGetUserlistQuery,
  useHardDeleteUsersMutation,
} from "../../../api/users/users";
import { Check, Close } from "@mui/icons-material";
import { UserModel } from "../../../components/molecules/UserModal";

interface Item {
  _id: string;
  fullName: string;
  email: string;
  roles: string[];
  active: boolean;
}

function UsersPage() {
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [isDelete, setDelete] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  // const [isAddNew, setAddNew] = React.useState(false);
  const [regex, setRegex] = React.useState<string>("");
  const [sortType, setSortType] = React.useState<string>("createdAt");
  const [updateType, setUpdateType] = React.useState<string>("createdAt");
  const [currentId, setCurrentId] = React.useState<GridRowId>("");
  const [currentItem, setCurrentItem] = React.useState<Item>();
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>();
  const [selectedRowIds, setSelectedRowIds] = React.useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  const {
    data: UsersData,
    isFetching: UsersIsFetching,
    isSuccess: UsersSuccess,
  } = useGetUserlistQuery({
    fullName: regex,
    pageSize: paginationModel.pageSize,
    pageNo: paginationModel.page + 1,
    sortType:
      sortType === "unPublished"
        ? "isPublished"
        : sortType === "published"
        ? "isPublished"
        : sortType,
    sortOrder: sortType === "unPublished" ? 1 : sortType === "title" ? 1 : -1,
  });

  const [update, { isLoading: updateIsLoading, isSuccess: updateSuccess }] =
    useUpdateUserMutation({});

  const [
    bulkUpdateData,
    {
      data: bulkUpdatedData,
      isLoading: isbulkUpdatedLoading,
      isSuccess: isbulkUpdatedSuccess,
    },
  ] = useBulkUpdateUsersMutation({});

  // const [create, { isLoading: createIsLoading, isSuccess: createSuccess }] =
  //   useCreateSubBrandMutation({});

  // const handleCreateClick = () => {
  //   setAddNew(true);
  //   setCurrentId("");
  //   setSubBrand("");
  // };
  //
  // function handleCreateNew(e: React.FormEvent<HTMLFormElement>) {
  //   e.preventDefault();
  //   create({ title: SubBrand, brandId: brandId })
  //     .unwrap()
  //     .then((res) => {
  //       console.log(res);
  //       snackbar.success(res.message);
  //       setAddNew(false);
  //       setBrandId("");
  //       setCurrentId("");
  //       setSubBrand("");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       snackbar.error(error?.data?.message);
  //     });
  // }

  const [deleteUser, { isLoading: deleteIsLoading, isSuccess: deleteSuccess }] =
    useDeleteUserMutation({});

  const [hardDeleteUser, { isLoading: hardDeleteIsLoading, isSuccess: hardDeleteSuccess }] = useHardDeleteUsersMutation({})

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 100  },
    { field: "name", headerName: "Name", width: 250  },
    { field: "email", headerName: "Email", width: 250  },
    { field: "role", headerName: "Role", width: 100  },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 180,
      cellClassName: "actions",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon className="text-blue-400" />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.row.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon className="text-red-400" />}
            label="Delete"
            onClick={handleDeleteClick(params.row.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <>
                {!params?.row?.active ? (
                  <Close
                    className={`text-white bg-yellow-500 rounded-full p-0.5`}
                  />
                ) : (
                  <Check
                    className={`text-white bg-green-500 rounded-full p-0.5`}
                  />
                )}
              </>
            }
            label="Block"
            onClick={handleSoftDelete(params.row.id, params.row.isBlocked)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    if (UsersSuccess && UsersData) {
      setRows(
        UsersData.data.data.map((item: Item) => ({
          id: item?._id,
          name: item?.fullName,
          email: item?.email,
          role: item?.roles[0],
          active: item.active,
        }))
      );
    }
  }, [UsersData, UsersSuccess]);

  const handleSelectionChange = (newSelection: GridRowId[]) => {
    console.log(newSelection);
    setSelectedRowIds(newSelection);
  };

  const handleEditClick = (id: GridRowId) => () => {
    setEdit(true);
    setCurrentId(id);
    console.log(UsersData.data.data.filter((item: Item) => item._id === id)[0]);
    setCurrentItem(
      UsersData.data.data.filter((item: Item) => item._id === id)[0]
    );
    console.log(
      UsersData.data.data.filter((item: Item) => item._id === id)[0]?.roles[0]
    );
    console.log(id);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDelete(true);
    setCurrentId(id);
    console.log(id);
  };

  function handleDelete() {
    hardDeleteUser({ id: currentId })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res.message);
        setDelete(false);
        setCurrentId("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setDelete(false);
      });
  }

  const handleSoftDelete = (id: GridRowId, isBlocked: GridRowId) => () => {
    deleteUser({id: id})
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res.message);
        setDelete(false);
        setCurrentId("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setDelete(false);
      });

    console.log(id);
  };

  function bulkUpdate() {
    console.log(updateType);
    let data;
    if (updateType === "delete") {
      data = { active: false };
    }
    bulkUpdateData({ id: selectedRowIds, data })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res.message);
        setUpdateType("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setUpdateType("");
      });
  }

  return (
    <AdminLayout>
      <AdminPageFilters
        name="Users"
        qty={UsersData?.data?.totalCount}
        // setAddNew={handleCreateClick}
        setSortType={setSortType}
        setRegex={setRegex}
        bulkUpdate={bulkUpdate}
        setUpdateType={setUpdateType}
        regex={regex}
      />
      <DataGridBox
        columns={columns}
        initialRows={rows}
        rowModesModel={rowModesModel}
        selectedRowIds={selectedRowIds}
        paginationModel={paginationModel}
        setRowModesModel={setRowModesModel}
        qty={UsersData?.data?.totalCount}
        setPaginationModel={setPaginationModel}
        onSelectionModelChange={handleSelectionChange}
      />
      {isDelete && (
        <DeletePopup
          open={isDelete}
          handleClose={() => setDelete(false)}
          handleDelete={handleDelete}
        />
      )}
      {isEdit && (
        <UserModel
          open={isEdit}
          item={currentItem}
          handleClose={() => setEdit(false)}
        />
      )}
      {/* {isAddNew && (
        <EditModal open={isAddNew} handleClose={() => setAddNew(false)}>
          <form className="flex flex-col" onSubmit={(e) => handleCreateNew(e)}>
            <h1 className="text-lg font-medium mb-4 w-full">Create SubBrand</h1>
            <InputFieldV2
              name="title"
              label="SubBrand"
              type="text"
              placeholder="SubBrand"
              handleChange={setSubBrand}
            />
            <SelectBoxV2 value="" label="Brand" handleChange={setBrandId}>
              <>
                <option value={""}></option>
                {BrandData &&
                  BrandData?.data?.data.map(
                    (item: { _id: string; title: string }) => (
                      <option value={item._id}>{item.title}</option>
                    )
                  )}
              </>
            </SelectBoxV2>
            <ButtonV1 color="blue" type="submit">
              Add SubBrand
            </ButtonV1>
          </form>
        </EditModal>
      )} */}
    </AdminLayout>
  );
}

export default UsersPage;
