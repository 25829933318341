import "./userDashboard.css";
import { useState } from "react";

import { CreativeFilter } from "../../components/atoms/creativeFilter";
import { Header } from "../../components/molecules/header";
import { SidebarUser } from "../../components/molecules/sidebar";
import { UserCardSection } from "../../components/organisms/userCardSection";
import { authDetails } from "../../api/auth/authActions";
import { useNavigate } from "react-router-dom";
import { getAuthFromLocal } from "../../utils/storage";

function UserDashboard() {
  
  const navigate = useNavigate();
  let data: authDetails = getAuthFromLocal();
  !data &&  navigate("/");

  const [length, setLength] = useState<number>();
  const [isLoading, setIsLoading] = useState(true);
  const [regex, setRegex] = useState<string>("");
  const [keyword, setKeyword] = useState<string>("");
  const [order, setOrder] = useState<number>(-1);
  const [pageNo, setPageNo] = useState(1);

  if (!data) {
    return <Header setRegex={setRegex} setKeyword={setKeyword} setPageNo={setPageNo} regex={regex} />; // Or a loading spinner, or a minimal component that doesn't trigger API calls
  }

  return (
    <div className="relative">
      <Header setRegex={setRegex} setKeyword={setKeyword} setPageNo={setPageNo} regex={regex} />
      <div className="flex min-h-[90vh]">
        <SidebarUser
          regex={keyword}
          pageNo={pageNo}
          setPageNo={setPageNo}
          setRegex={setRegex}
          setKeyword={setKeyword}
          setIsLoading={setIsLoading}
        />
        <div className="w-[80%]">
          <CreativeFilter setOrder={setOrder} length={length} isLoading={isLoading} />
          <UserCardSection
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            regex={keyword}
            length={length}
            sortOrder={order}
            sortType="createdAt"
            pageNo={pageNo}
            setPageNo={setPageNo}
            setLength={setLength}
          />
        </div>
      </div>
    </div>
  );
}

export default UserDashboard;
