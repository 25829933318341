import { useEffect, useState } from "react";
import { ButtonV1, ButtonV2 } from "../../components/atoms/button";
import { InputField } from "../../components/atoms/inputField";
import { useNavigate } from "react-router-dom";
import {
  useChangeForgotPasswordMutation,
  useForgotPasswordMutation,
  useVerifyForgotPasswordMutation,
} from "../../api/auth/authApiSlice";
import { useFormik } from "formik";
import snackbar from "../../api/snackbar/useSnackbar";
import { authDetails } from "../../api/auth/authActions";
import { getAuthFromLocal, saveToLocal } from "../../utils/storage";
import OtpVerification from "../../components/molecules/otpVerification/OtpVerification";
import { useValidationSchemas } from "../../validationSchema/profileSchema";

function ForgotDashboard() {
  const {
    changeForgotPasswordSchema,
    forgotPasswordSchema,
    verifyForgotPasswordSchema,
  } = useValidationSchemas();
  const navigate = useNavigate();
  const [active, setActive] = useState("1");

  let data: authDetails = getAuthFromLocal();

  useEffect(() => {
    data && navigate("/home");
  }, [data]);

  const [forgotPassword] = useForgotPasswordMutation();

  const [verifyForgotPassword] = useVerifyForgotPasswordMutation();

  const [changeForgotPassword] = useChangeForgotPasswordMutation();

  const Forgot = useFormik({
    initialValues: {
      email: "",
    },
    enableReinitialize: true,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "Engage_Tap_Forgot_Submit",
          category: "User Interaction",
          action: "Button Click",
          label: "Forgot Submit Button",
        });
      }
      forgotPassword(values)
        .unwrap()
        .then((res: { data: any; message: string }) => {
          // console.log(res);
          saveToLocal("accessToken", res?.data?.authToken);
          snackbar.success(res.message);
          setActive("2");
        })
        .catch((error: { data: { message: string } }) => {
          console.log(error);
          snackbar.error(error?.data?.message);
        });
    },
  });

  const VerifyForgot = useFormik({
    initialValues: {
      email: Forgot.values.email,
      code: "",
    },
    enableReinitialize: true,
    validationSchema: verifyForgotPasswordSchema,
    onSubmit: (values) => {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "Engage_Tap_Verify_Forgot",
          category: "User Interaction",
          action: "Button Click",
          label: "Verify Forgot Button",
        });
      }
      verifyForgotPassword(values)
        .unwrap()
        .then((res: { data: any; message: string }) => {
          console.log(res);
          saveToLocal("accessToken", res.data.authToken);
          snackbar.success(res.message);
          setActive("3");
        })
        .catch((error: { data: { message: string } }) => {
          console.log(error);
          snackbar.error(error?.data?.message);
        });
    },
  });

  const ChangeForgot = useFormik({
    initialValues: {
      email: VerifyForgot.values.email || "",
      password: "",
      confirmPassword: "",
    },
    enableReinitialize: true,
    validationSchema: changeForgotPasswordSchema,
    onSubmit: (values) => {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "Engage_Tap_Change_Forgot_Submit",
          category: "User Interaction",
          action: "Button Click",
          label: "Change Forgot Submit Button",
        });
      }
      changeForgotPassword(values)
        .unwrap()
        .then((res: { data: any; message: string }) => {
          console.log(res);
          saveToLocal("accessToken", res.data.authToken);
          snackbar.success(res.message);
          navigate("/");
        })
        .catch((error: { data: { message: string } }) => {
          console.log(error);
          snackbar.error(error?.data?.message);
        });
    },
  });

  const handleClick = (name: string) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_${name}`,
        category: "User Interaction",
        action: "Button Click",
        label: `${name} Button`,
      });
    }
  };

  return (
    <div className="relative w-screen h-screen overflow-hidden flex justify-center items-center">
      <div
        className={`bg-white w-1/4 max-h-4/6 h-auto min-w-[20rem] md:min-w-[25rem] min-[1000px]:min-w-[25rem] overflow-y-scroll border-t rounded-xl shadow-lg shadow-slate-300 px-[2.5vw] py-[5vh] flex justify-between items-center flex-col effect
        heightTran`}
      >
        <img
          src="/img/logo.svg"
          alt="logo"
          className="h-auto w-4/6 min-w-[10rem] mb-6"
        />
        {active == "1" ? (
          <form
            noValidate
            onSubmit={Forgot.handleSubmit}
            className={`w-full flex flex-col gap-1 fadeEffect heightTran ${
              active == "1" ? "opacity-100 expanded" : "opacity-0"
            } `}
          >
            <p className="text-center font-semibold mb-4">
              To Update your password please enter your existing email to
              continue
            </p>
            <InputField
              name="email"
              formik={Forgot}
              label="Email"
              type="email"
              placeholder="eg: abc@mail.com"
            />
            <ButtonV1 type="submit" color="blue">
              Continue
            </ButtonV1>
            <ButtonV2
              type="button"
              setActive={() => {
                handleClick("Login");
                navigate("/");
              }}
              color="blue"
            >
              Go to Login
            </ButtonV2>
          </form>
        ) : active === "2" ? (
          <form
            noValidate
            onSubmit={VerifyForgot.handleSubmit}
            className={`w-full flex flex-col gap-1 fadeEffect heightTran ${
              active === "2" ? "opacity-100 expanded" : "opacity-0"
            } `}
          >
            <p className="text-center font-semibold mb-4">
              Please enter the OTP you recived!!
            </p>
            <OtpVerification formik={VerifyForgot} name={"code"} />
            <ButtonV1 color="blue" type="submit">
              Verify
            </ButtonV1>
          </form>
        ) : (
          <form
            noValidate
            onSubmit={ChangeForgot.handleSubmit}
            className={`w-full flex flex-col gap-1 fadeEffect heightTran ${
              active === "3" ? "opacity-100 expanded" : "opacity-0"
            } `}
          >
            <h1 className="font-semibold text-xl text-center mb-4 tracking-tight leading-relaxed">
              Please create your New Password
            </h1>
            <InputField
              name="password"
              label="New Password"
              type="password"
              placeholder="Password"
              formik={ChangeForgot}
            />
            <InputField
              name="confirmPassword"
              label="Confirm New Password"
              type="password"
              placeholder="Confirm password"
              formik={ChangeForgot}
            />
            <div className="mb-2">
              <ul className="list-disc">
                {" "}
                Password requirements include:
                <li>* 1 capital letter</li>
                <li>* 1 lowercase letter</li>
                <li>* 1 special symbol</li>
                <li>* 1 numeric character</li>
                <li>* There must be 8 characters</li>
              </ul>
            </div>
            <ButtonV1 color="blue" type="submit">
              Update
            </ButtonV1>
          </form>
        )}
      </div>
    </div>
  );
}

export default ForgotDashboard;
