import apiSlice from "../features/apiSlice";
import { removeEmptyKeys } from "../utils/helper";

const uploadImageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    generateThumb: builder.mutation({
      query: (credentials) => {
        return {
          url: `/thumb`,
          method: "POST",
          body: credentials,
        };
      },
    }),
  }),
});

export const { useGenerateThumbMutation } = uploadImageApiSlice;
