import { Box, Modal } from "@mui/material";
import ReactPlayer from "react-player";
import CopyToClipboard from "react-copy-to-clipboard";
import { ButtonV1 } from "../atoms/button";
import { Close, FileDownload, Link } from "@mui/icons-material";
import { useEffect, useState } from "react";

interface props {
  open: boolean;
  handleClose: VoidFunction;
  item: {
    stage: string;
    title: string;
    thumbLink: string;
    videoLink: string;
  };
}

export function VideoPopup({ handleClose, item, open }: props) {
  const [copy, setCopy] = useState(false);

  // const [downloadVideo] = useDownloadVideoMutation({});

  // const handleDownload = async (videoLink: string, title: string) => {
  //   try {
  //     downloadVideo({
  //       url: videoLink,
  //     })
  //       .unwrap()
  //       .then((response) => {
  //         console.log("🚀 ~ .then ~ response:", response?.data?.url);
  //         const url = response?.data?.url;
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.setAttribute("download", title + ".mp4");
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);
  //       });
  //   } catch (error) {
  //     console.log("Something went wrong");
  //   }
  // };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70vw",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 4,
    p: 4
  };

  const newTitle = encodeURI(item?.title).replaceAll("+", "%2B");

  const handleClick = (name: string) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_${name}`,
        category: "User Interaction",
        action: "Button Click",
        label: `${name} Button`,
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} borderRadius={"1.5rem"}>
        <div
          className="absolute right-5 top-5 cursor-pointer p-2 z-10"
          onClick={handleClose}
        >
          <Close className="cursor-pointer" />
        </div>
        <div>
          <div className="flex items-center gap-0.5 h-max font-medium p-2 pt-3 bg-white">
            <span
              className={`flex ${item.stage === "postsale" ? "bg-black" : "bg-gray-500"
                } w-1 m-1 aspect-square h-max p-1.5 rounded-full`}
            ></span>
            {item.stage}
          </div>
          <div className="w-max h-max rounded-xl border-2 border-[#EEF2F7] mx-auto overflow-hidden">
            <ReactPlayer
              url={item.videoLink}
              playing
              controls
              className="max-h-[60vh] max-w-full overflow-hidden"
            />
          </div>
          <div className="flex justify-between pt-4 ">
            <h1 className="max-w-2/3 text-wrap whitespace-normal text-lg">
              {item.title}
            </h1>
            <div className="flex gap-4 relative">
              <ButtonV1 color={`${copy ? "red" : "blue"}`}>
                <CopyToClipboard
                  text={
                    `${window.location.origin +
                    "/videoplayer?videoUrl=" +
                    item?.videoLink +
                    "&stage=" +
                    item.stage +
                    "&videoName=" +
                    newTitle
                    }` || "video"
                  }
                  onCopy={() => {
                    handleClick("CopyLink");
                    setCopy(true);
                  }}
                >
                  {copy ? (
                    <p className="capitalize">copied</p>
                  ) : (
                    <Link className="text-white" />
                  )}
                </CopyToClipboard>
              </ButtonV1>
              <ButtonV1 color="blue">
                <a
                  href={item.videoLink}
                  download={item.title}
                  onClick={() => handleClick("Download")}
                  id="videoPlayer"
                >
                  <FileDownload className="text-white" />
                </a>
              </ButtonV1>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
