import React from "react";
import { VideoPopup } from "./VideoPopup";

interface props {
  item: {
    stage: string;
    title: string;
    thumbLink: string;
    videoLink: string;
  };
}

export function UserProductCard({ item }: props) {
  const [isView, setIsView] = React.useState(false);
  function handleClose() {
    setIsView(false);
  }
  
  // console.log(item.stage);
  
  return (
    <>
      <div
        className="bg-[#EEF2F7] boxes rounded-xl border overflow-hidden cursor-pointer w-full"
        onClick={() => setIsView(true)}
      >
        <div className="flex items-center gap-0.5 h-max font-medium p-2 pt-3 bg-white ">
          <span
            className={`flex  ${item.stage === 'Postsale' ? "bg-black" : item.stage === 'Presale' ? "bg-gray-400" : "bg-[#DA1A42]"} w-1 m-1 aspect-square h-max p-1.5 rounded-full`}
          ></span>
          {item.stage}
        </div>
        <img src={item.thumbLink} alt="thumbnail" />
        <p className="h-full bg-white text-[14px] font-bold p-4">
          {item.title}
        </p>
      </div>
      {isView && (
        <VideoPopup open={isView} handleClose={handleClose} item={item} />
      )}
    </>
  );
}
