import apiSlice from "../../features/apiSlice";
import { removeEmptyKeys } from "../../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSpecialKeyword: builder.query({
      query: (queries) => {
        return {
          url: '/specialKeyword',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['SpecialKeyword']
    }),
    createSpecialKeyword: builder.mutation({
      query: (credentials) => ({
        url: "/specialKeyword",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['SpecialKeyword']
    }),
    updateSpecialKeyword: builder.mutation({
      query: (credentials) => ({
        url: `/specialKeyword/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags:['SpecialKeyword']
    }),
    bulkUpdateSpecialKeyword: builder.mutation({
      query: (credentials) => ({
        url: `/specialKeyword/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['SpecialKeyword']
    }),
    deleteSpecialKeyword: builder.mutation({
      query: (credentials) => ({
        url: `/specialKeyword/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['SpecialKeyword']
    }),
    hardDeleteSpecialKeyword: builder.mutation({
      query: (credentials) => ({
        url: `/specialKeyword/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['SpecialKeyword']
    }),
  }),
});

export const {
  useGetSpecialKeywordQuery,
  useCreateSpecialKeywordMutation,
  useUpdateSpecialKeywordMutation,
  useBulkUpdateSpecialKeywordMutation,
  useDeleteSpecialKeywordMutation,
  useHardDeleteSpecialKeywordMutation
} = authApiSlice;
