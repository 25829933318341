import AdminLayout from "../../../components/organisms/adminLayout";
import DataGridBox from "../../../components/organisms/dataGrid";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
} from "@mui/x-data-grid";
import React, { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AdminPageFilters from "../../../components/organisms/adminPageFilters";
import {
  useBulkUpdateCountryMutation,
  useCreateCountryMutation,
  useDeleteCountryMutation,
  useGetCountryQuery,
  useUpdateCountryMutation,
} from "../../../api/filters/country";
import { useGetRegionQuery } from "../../../api/filters/region";
import { DeletePopup } from "../../../components/molecules/DeleteModal";
import { EditModal } from "../../../components/molecules/EditModal";
import { InputFieldV2 } from "../../../components/atoms/inputField";
import { ButtonV1 } from "../../../components/atoms/button";
import snackbar from "../../../api/snackbar/useSnackbar";
import { SelectBoxV2 } from "../../../components/atoms/selectBox";

interface Item {
  _id: string;
  title: string;
  regionId: string;
}

function CountryPage() {
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [isDelete, setDelete] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [isAddNew, setAddNew] = React.useState(false);
  const [Country, setCountry] = React.useState("");
  const [regionId, setRegionId] = React.useState("");
  const [regex, setRegex] = React.useState<string>("");
  const [sortType, setSortType] = React.useState<string>("createdAt");
  const [updateType, setUpdateType] = React.useState<string>("createdAt");
  const [sortOrder, setSortOrder] = React.useState<Number>(-1);
  const [currentId, setCurrentId] = React.useState<GridRowId>("");
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>();
  const [selectedRowIds, setSelectedRowIds] = React.useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  const {
    data: regionData,
    isFetching: regionIsFetching,
    isSuccess: regionSuccess,
  } = useGetRegionQuery({
    pageSize: 100000,
    pageNo: 1,
    sortType: 'title',
    sortOrder: 1,
  });

  const {
    refetch: CountryRefetch,
    data: CountryData,
    isFetching: CountryIsFetching,
    isSuccess: CountrySuccess,
  } = useGetCountryQuery({
    searchKeyword: regex,
    pageSize: paginationModel.pageSize,
    pageNo: paginationModel.page + 1,
    sortType: sortType,
    sortOrder: sortType === "title" ? 1 : sortOrder,
  });

  const [update, { isLoading: updateIsLoading, isSuccess: updateSuccess }] =
    useUpdateCountryMutation({});
  const [create, { isLoading: createIsLoading, isSuccess: createSuccess }] =
    useCreateCountryMutation({});

  const [
    bulkUpdateData,
    {
      data: bulkUpdatedData,
      isLoading: isbulkUpdatedLoading,
      isSuccess: isbulkUpdatedSuccess,
    },
  ] = useBulkUpdateCountryMutation({});

  const [
    deleteCountry,
    { isLoading: deleteIsLoading, isSuccess: deleteSuccess },
  ] = useDeleteCountryMutation({});

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 100  },
    { field: "name", headerName: "Country", width: 250  },
    { field: "regionId", headerName: "Region", width: 250  },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 180,
      cellClassName: "actions",
      getActions: ({ id }: GridRowParams<any>) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon className="text-blue-400" />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon className="text-red-400" />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    if (CountrySuccess && CountryData) {
      setRows(
        CountryData.data.data.map((item: Item) => ({
          id: item?._id,
          name: item?.title,
          regionId: item?.regionId,
        }))
      );
    }
  }, [
    CountryData,
    CountryIsFetching,
    CountrySuccess,
    regionData,
    regionIsFetching,
    regionSuccess,
  ]);

  useEffect(() => {
    CountryRefetch();
  }, []);

  const handleCreateClick = () => {
    setAddNew(true);
    setRegionId("");
    setCurrentId("");
    setCountry("");
  };

  const handleEditClick = (id: GridRowId) => () => {
    setEdit(true);
    setCurrentId(id);
    setRegionId(rows.find((item) => item.id === id)?.regionId);
    setCountry(rows.find((item) => item.id === id)?.name);
    console.log(id);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDelete(true);
    setCurrentId(id);
    console.log(id);
  };

  const handleSelectionChange = (newSelection: GridRowId[]) => {
    console.log(newSelection);
    setSelectedRowIds(newSelection);
  };

  function handleCreate(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    create({ title: Country, regionId: regionId })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res.message);
        setAddNew(false);
        setRegionId("");
        setCurrentId("");
        setCountry("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
      });
  }

  function handleUpdate(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    update({ id: currentId, body: { title: Country, regionId: regionId } })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res.message);
        setEdit(false);
        setRegionId("");
        setCurrentId("");
        setCountry("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
      });
  }

  function handleDelete() {
    deleteCountry({ id: currentId })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res.message);
        setDelete(false);
        setRegionId("");
        setCurrentId("");
        setCountry("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setDelete(false);
      });
  }

  function bulkUpdate() {
    console.log(updateType);

    let data;
    if (updateType === "delete") {
      data = { active: false };
    }
    bulkUpdateData({ id: selectedRowIds, data })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res.message);
        setUpdateType("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setUpdateType("");
      });
  }

  return (
    <AdminLayout>
      <AdminPageFilters
        name="Country"
        qty={CountryData?.data?.totalCount}
        setAddNew={handleCreateClick}
        setRegex={setRegex}
        bulkUpdate={bulkUpdate}
        setUpdateType={setUpdateType}
        sortType={sortType}
        setSortType={setSortType}
        regex={regex}
      />
      <DataGridBox
        columns={columns}
        initialRows={rows}
        rowModesModel={rowModesModel}
        selectedRowIds={selectedRowIds}
        paginationModel={paginationModel}
        setRowModesModel={setRowModesModel}
        qty={CountryData?.data?.totalCount}
        setPaginationModel={setPaginationModel}
        onSelectionModelChange={handleSelectionChange}
      />
      {isDelete && (
        <DeletePopup
          open={isDelete}
          handleClose={() => setDelete(false)}
          handleDelete={handleDelete}
        />
      )}
      {isEdit && (
        <EditModal open={isEdit} handleClose={() => setEdit(false)}>
          <form className="flex flex-col" onSubmit={(e) => handleUpdate(e)}>
            <h1 className="text-lg font-medium mb-4 w-full">Edit Country</h1>
            <InputFieldV2
              name="title"
              label="Name of Country"
              type="text"
              handleChange={setCountry}
              value={Country}
            />
            <SelectBoxV2
              value={regionId}
              label="Name of Region"
              handleChange={setRegionId}
            >
              <>
                <option value={""}></option>
                {regionData &&
                  regionData?.data?.data.map(
                    (item: { _id: string; title: string }) => (
                      <option value={item.title}>{item.title}</option>
                    )
                  )}
              </>
            </SelectBoxV2>
            <ButtonV1 color="blue" type="submit">
              Update
            </ButtonV1>
          </form>
        </EditModal>
      )}
      {isAddNew && (
        <EditModal open={isAddNew} handleClose={() => setAddNew(false)}>
          <form className="flex flex-col" onSubmit={(e) => handleCreate(e)}>
            <h1 className="text-lg font-medium mb-4 w-full">Edit Country</h1>
            <InputFieldV2
              name="title"
              label="Name of Country"
              type="text"
              placeholder="Add new Country"
              handleChange={setCountry}
            />
            <SelectBoxV2
              value=""
              label="Name of Region"
              handleChange={setRegionId}
            >
              <>
                <option value={""}></option>
                {regionData &&
                  regionData?.data?.data.map(
                    (item: { _id: string; title: string }) => (
                      <option value={item.title}>{item.title}</option>
                    )
                  )}
              </>
            </SelectBoxV2>
            <ButtonV1 color="blue" type="submit">
              Add Country
            </ButtonV1>
          </form>
        </EditModal>
      )}
    </AdminLayout>
  );
}

export default CountryPage;
