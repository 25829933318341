import apiSlice from "../../features/apiSlice";
import { removeEmptyKeys } from "../../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdType: builder.query({
      query: (queries) => {
        return {
          url: '/adType',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['AdType']
    }),
    createAdType: builder.mutation({
      query: (credentials) => ({
        url: "/adType",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['AdType']
    }),
    updateAdType: builder.mutation({
      query: (credentials) => ({
        url: `/adType/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags:['AdType']
    }),
    bulkUpdateAdType: builder.mutation({
      query: (credentials) => ({
        url: `/adType/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['AdType']
    }),
    deleteAdType: builder.mutation({
      query: (credentials) => ({
        url: `/adType/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['AdType']
    }),
    hardDeleteAdType: builder.mutation({
      query: (credentials) => ({
        url: `/adType/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['AdType']
    }),
  }),
});

export const {
  useGetAdTypeQuery,
  useCreateAdTypeMutation,
  useUpdateAdTypeMutation,
  useBulkUpdateAdTypeMutation,
  useDeleteAdTypeMutation,
  useHardDeleteAdTypeMutation
} = authApiSlice;
