import AdminLayout from "../../../components/organisms/adminLayout";
import DataGridBox from "../../../components/organisms/dataGrid";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
} from "@mui/x-data-grid";
import React, { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AdminPageFilters from "../../../components/organisms/adminPageFilters";
import {
  useBulkUpdateCreativeFormatMutation,
  useCreateCreativeFormatMutation,
  useDeleteCreativeFormatMutation,
  useGetCreativeFormatQuery,
  useUpdateCreativeFormatMutation,
} from "../../../api/filters/creativeFormat";
import { useGetRegionQuery } from "../../../api/filters/region";
import { DeletePopup } from "../../../components/molecules/DeleteModal";
import { EditModal } from "../../../components/molecules/EditModal";
import { InputFieldV2 } from "../../../components/atoms/inputField";
import { ButtonV1 } from "../../../components/atoms/button";
import snackbar from "../../../api/snackbar/useSnackbar";
import { SelectBoxV2 } from "../../../components/atoms/selectBox";

interface Item {
  _id: string;
  title: string;
  region: {
    title: string;
    _id: string;
  }[];
}

function CreativeFormatPage() {
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [isDelete, setDelete] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [isAddNew, setAddNew] = React.useState(false);
  const [CreativeFormat, setCreativeFormat] = React.useState("");
  const [regionId, setRegionId] = React.useState("");
  const [regex, setRegex] = React.useState<string>("");
  const [sortType, setSortType] = React.useState<string>("createdAt");
  const [updateType, setUpdateType] = React.useState<string>("createdAt");
  const [currentId, setCurrentId] = React.useState<GridRowId>("");
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>();
  const [selectedRowIds, setSelectedRowIds] = React.useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  const {
    data: regionData,
    isFetching: regionIsFetching,
    isSuccess: regionSuccess,
  } = useGetRegionQuery({
    pageSize: 100000,
    pageNo: 1,
    sortType: 'title',
    sortOrder: 1,
  });

  const {
    refetch: CreativeFormatRefetch,
    data: CreativeFormatData,
    isFetching: CreativeFormatIsFetching,
    isSuccess: CreativeFormatSuccess,
  } = useGetCreativeFormatQuery({
    searchKeyword: regex,
    pageSize: paginationModel.pageSize,
    pageNo: paginationModel.page + 1,
    sortType: sortType,
    sortOrder: sortType === "title" ? 1 : -1,
  });

  const [update, { isLoading: updateIsLoading, isSuccess: updateSuccess }] =
    useUpdateCreativeFormatMutation({});
  const [create, { isLoading: createIsLoading, isSuccess: createSuccess }] =
    useCreateCreativeFormatMutation({});

  const [
    bulkUpdateData,
    {
      data: bulkUpdatedData,
      isLoading: isbulkUpdatedLoading,
      isSuccess: isbulkUpdatedSuccess,
    },
  ] = useBulkUpdateCreativeFormatMutation({});

  const [
    deleteCreativeFormat,
    { isLoading: deleteIsLoading, isSuccess: deleteSuccess },
  ] = useDeleteCreativeFormatMutation({});

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 100  },
    { field: "name", headerName: "Name", width: 250  },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 180,
      cellClassName: "actions",
      getActions: ({ id }: GridRowParams<any>) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon className="text-blue-400" />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon className="text-red-400" />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    if (CreativeFormatSuccess && CreativeFormatData) {
      setRows(
        CreativeFormatData.data.data.map((item: Item) => ({
          id: item?._id,
          name: item?.title,
        }))
      );
    }
  }, [
    CreativeFormatData,
    CreativeFormatIsFetching,
    CreativeFormatSuccess,
    regionData,
    regionIsFetching,
    regionSuccess,
  ]);

  useEffect(() => {
    CreativeFormatRefetch();
  }, []);

  const handleCreateClick = () => {
    setAddNew(true);
    setRegionId("");
    setCurrentId("");
    setCreativeFormat("");
  };

  const handleEditClick = (id: GridRowId) => () => {
    setEdit(true);
    setCurrentId(id);
    setRegionId(rows.find((item) => item.id === id)?.regionId);
    setCreativeFormat(rows.find((item) => item.id === id)?.name);
    console.log(id);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDelete(true);
    setCurrentId(id);
    console.log(id);
  };

  const handleSelectionChange = (newSelection: GridRowId[]) => {
    console.log(newSelection);
    setSelectedRowIds(newSelection);
  };

  function handleCreate(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    create({ title: CreativeFormat })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res.message);
        setAddNew(false);
        setRegionId("");
        setCurrentId("");
        setCreativeFormat("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
      });
  }

  function handleUpdate(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    update({ id: currentId, body: { title: CreativeFormat, regionId: regionId } })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res.message);
        setEdit(false);
        setRegionId("");
        setCurrentId("");
        setCreativeFormat("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
      });
  }

  function handleDelete() {
    deleteCreativeFormat({ id: currentId })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res.message);
        setDelete(false);
        setRegionId("");
        setCurrentId("");
        setCreativeFormat("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setDelete(false);
      });
  }

  function bulkUpdate() {
    console.log(updateType);

    let data;
    if (updateType === "delete") {
      data = { active: false };
    }
    bulkUpdateData({ id: selectedRowIds, data })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res.message);
        setUpdateType("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setUpdateType("");
      });
  }

  return (
    <AdminLayout>
      <AdminPageFilters
        name="CreativeFormat"
        qty={CreativeFormatData?.data?.totalCount}
        setAddNew={handleCreateClick}
        setRegex={setRegex}
        bulkUpdate={bulkUpdate}
        setUpdateType={setUpdateType}
        sortType={sortType}
        setSortType={setSortType}
        regex={regex}
      />
      <DataGridBox
        columns={columns}
        initialRows={rows}
        rowModesModel={rowModesModel}
        selectedRowIds={selectedRowIds}
        paginationModel={paginationModel}
        setRowModesModel={setRowModesModel}
        qty={CreativeFormatData?.data?.totalCount}
        setPaginationModel={setPaginationModel}
        onSelectionModelChange={handleSelectionChange}
      />
      {isDelete && (
        <DeletePopup
          open={isDelete}
          handleClose={() => setDelete(false)}
          handleDelete={handleDelete}
        />
      )}
      {isEdit && (
        <EditModal open={isEdit} handleClose={() => setEdit(false)}>
          <form className="flex flex-col" onSubmit={(e) => handleUpdate(e)}>
            <h1 className="text-lg font-medium mb-4 w-full">Edit CreativeFormat</h1>
            <InputFieldV2
              name="title"
              label="Name of CreativeFormat"
              type="text"
              handleChange={setCreativeFormat}
              value={CreativeFormat}
            />
            <ButtonV1 color="blue" type="submit">
              Update
            </ButtonV1>
          </form>
        </EditModal>
      )}
      {isAddNew && (
        <EditModal open={isAddNew} handleClose={() => setAddNew(false)}>
          <form className="flex flex-col" onSubmit={(e) => handleCreate(e)}>
            <h1 className="text-lg font-medium mb-4 w-full">Edit CreativeFormat</h1>
            <InputFieldV2
              name="title"
              label="Name of CreativeFormat"
              type="text"
              placeholder="Add new CreativeFormat"
              handleChange={setCreativeFormat}
            />
            <ButtonV1 color="blue" type="submit">
              Add CreativeFormat
            </ButtonV1>
          </form>
        </EditModal>
      )}
    </AdminLayout>
  );
}

export default CreativeFormatPage;
