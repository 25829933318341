/* eslint-disable react/display-name */

import { Ref, forwardRef, useCallback } from "react";
import { useSnackbar, SnackbarContent } from "notistack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import HelpIcon from "@mui/icons-material/Help";

interface MessageProps {
  id: string;
  message: string;
  className: string;
  icon: any;
}

const MessageBase = forwardRef(({ id, ...props }: MessageProps, ref) => {
// const MessageBase = forwardRef(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref as Ref<HTMLDivElement>}>
      <Card className={`snackbar ${props.className}`}>
        <div className="container">
          {props.icon}
          <Typography variant="body2">{props.message}</Typography>
          <IconButton size="small" onClick={handleDismiss}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </Card>
    </SnackbarContent>
  );
});

const SuccessMessage = forwardRef<HTMLDivElement, MessageProps>((props, ref) => (
  <MessageBase {...props} ref={ref}
    className="success"
    icon={<CheckCircleOutlineIcon fontSize="small" sx={{ color: "#A6FAAF" }} />} />
));

const ErrorMessage = forwardRef<HTMLDivElement, MessageProps>((props, ref) => (
  <MessageBase {...props} ref={ref} 
  className="error" 
  icon={<ErrorOutlineIcon fontSize="small" sx={{ color: "#F67476" }} />} />
));

const WarningMessage = forwardRef<HTMLDivElement, MessageProps>((props, ref) => (
  <MessageBase {...props} ref={ref} 
    className="warning" 
    icon={<WarningAmberIcon fontSize="small" sx={{ color: "#FFC75B" }} />} />
));

const InfoMessage = forwardRef<HTMLDivElement, MessageProps>((props, ref) => (
  <MessageBase {...props} ref={ref} 
    className="info" 
    icon={<HelpIcon fontSize="small" sx={{ color: "#C8D8FF" }} />} />
));

export { SuccessMessage, ErrorMessage, WarningMessage, InfoMessage };
