import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  errorMessage: "",
  status: "initial",
  cartData: {
    status: "initial",
    loading: false,
    error: false,
    data: null,
    cartItems: [],
    count: 0,
  },
  successMerge: false,
};
const cartReducer = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setResetcart: (state) => {
      state.errorMessage = "";
      state.status = "initial";
      state.cartData = {
        status: "initial",
        loading: false,
        error: false,
        data: null,
        cartItems: [],
        count: 0,
      };
    },
    setCartData: (state, action) => {
      state.cartData = { ...state.cartData, ...action.payload };
    },
    setSuccessMerge: (state, action) => {
      console.log(action.payload, "called success cart merge 02 inner");
      state.successMerge = action.payload;
    },
  },
});

export default cartReducer.reducer;
export const { actions: cartActions } = cartReducer;
