import "./dataGrid.css";
import * as React from "react";
import Box from "@mui/material/Box";
import {
  GridRowsProp,
  GridRowModesModel,
  DataGrid,
  GridColDef,
  GridCallbackDetails,
  GridRowId,
  GridRowSelectionModel,
  GridPaginationModel,
} from "@mui/x-data-grid/";
import { useDispatch, useSelector } from "react-redux";
import { storeHandler } from "../../api/store/storeActions";
interface props {
  qty?: number;
  initialRows: GridRowsProp | ItemSchema[];
  columns: GridColDef[];
  rowModesModel: GridRowModesModel | undefined;
  setRowModesModel?: React.Dispatch<
    React.SetStateAction<GridRowModesModel | undefined>
  >;
  selectedRowIds: GridRowId[];
  onSelectionModelChange?: (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails<any>
  ) => void;
  paginationModel?: GridPaginationModel | undefined;
  setPaginationModel?:
    | ((model: GridPaginationModel, details: GridCallbackDetails<any>) => void)
    | undefined;
}

interface ItemSchema {
  srno: number;
  id: string;
  name: string;
  img: string;
  videoLink: string;
  isPublished: boolean;
}

export default function DataGridBox({
  initialRows,
  columns,
  rowModesModel,
  setRowModesModel,
  selectedRowIds,
  onSelectionModelChange,
  paginationModel,
  setPaginationModel,
  qty,
}: props) {

  return (
    <Box
      sx={{
        height: 1000,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        rowCount={qty}
        rows={initialRows}
        className="flex-col-reverse"
        columns={columns}
        checkboxSelection
        rowSelectionModel={selectedRowIds}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onRowSelectionModelChange={onSelectionModelChange}
        paginationMode="server"
        // slotProps={{
        //   toolbar: { setRowModesModel },
        // }}
        pageSizeOptions={[ 50, 100, 25,]}
        disableRowSelectionOnClick
        rowHeight={100}
        rowSpacingType="border"
      />
    </Box>
  );
}
