import apiSlice from "../../features/apiSlice";
import { removeEmptyKeys } from "../../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStage: builder.query({
      query: (queries) => {
        return {
          url: '/stage',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['Stage']
    }),
    createStage: builder.mutation({
      query: (credentials) => ({
        url: "/stage",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Stage']
    }),
    updateStage: builder.mutation({
      query: (credentials) => ({
        url: `/stage/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags:['Stage']
    }),
    bulkUpdateStage: builder.mutation({
      query: (credentials) => ({
        url: `/stage/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Stage']
    }),
    deleteStage: builder.mutation({
      query: (credentials) => ({
        url: `/stage/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Stage']
    }),
    hardDeleteStage: builder.mutation({
      query: (credentials) => ({
        url: `/stage/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Stage']
    }),
  }),
});

export const {
  useGetStageQuery,
  useCreateStageMutation,
  useUpdateStageMutation,
  useBulkUpdateStageMutation,
  useDeleteStageMutation,
  useHardDeleteStageMutation
} = authApiSlice;
