import { useLocation, useNavigate } from "react-router-dom";
import { ButtonV4 } from "../atoms/button";
import SearchBar from "../atoms/searchBar";
import { getAuthFromLocal } from "../../utils/storage";
import { useEffect } from "react";
import { authDetails, loginHandler } from "../../api/auth/authActions";
import { useGetUserQuery } from "../../api/users/users";
import { useDispatch } from "react-redux";

interface props {
  setRegex: Function;
  setKeyword: Function;
  setPageNo: Function;
  regex: string;
}

export function Header({ setRegex, setKeyword, setPageNo, regex }: props) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const { pathname } = location;

  function navigation() {
    navigate("/admin");
  }
  function navigation2() {
    navigate("/");
  }

  let data: authDetails = getAuthFromLocal();

  const { refetch, data: userData } = useGetUserQuery({});

  useEffect(() => {
    // console.log("refetching");
    refetch();
    if (userData) {
      // console.log("refetching2", userData);
      let authDetails = {
        accessToken: userData.data.authToken,
        userId: userData.data._id,
        email: userData.data.email,
        fullName: userData.data.fullName,
        active: userData.data.active,
        isEmailVerified: userData.data.isEmailVerified,
        registrationStatus: userData.data.registrationStatus,
        roles: userData.data.roles[0],
      };
      dispatch(loginHandler(authDetails));
    }
    !data && navigate("/");
  }, [pathname]);

  const handleClick = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "Engage_Tap_Logo",
        category: "User Interaction",
        action: "Button Click",
        label: "Logo Button",
      });
    }
    window.location.reload();
  };

  return (
    <div className="sticky top-0 w-full h-[10vh] overflow-hidden bg-white px-[2vw] py-[2vh] border-b-2 flex justify-between z-50">
      <img
        src="/img/logo.svg"
        alt="logo"
        className="h-auto w-1/6 min-w-[10rem] object-contain cursor-pointer"
        onClick={() => handleClick()}
        role="presentation"
      />
      <div className="flex w-max gap-2">
        {userData?.data?.roles[0] !== "user" && (
          <ButtonV4 color="blue" setActive={navigation}>
            Admin Dashboard
          </ButtonV4>
        )}
        <SearchBar
          setRegex={setRegex}
          setKeyword={setKeyword}
          setPageNo={setPageNo}
          regex={regex}
        />
      </div>
    </div>
  );
}

export function AdminHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const { pathname } = location;

  function navigation() {
    navigate("/home");
  }

  const { refetch, data: userData } = useGetUserQuery({});

  let data: authDetails = getAuthFromLocal();

  useEffect(() => {
    refetch();
    if (userData) {
      let authDetails = {
        accessToken: userData.data.authToken,
        userId: userData.data._id,
        email: userData.data.email,
        fullName: userData.data.fullName,
        active: userData.data.active,
        isEmailVerified: userData.data.isEmailVerified,
        registrationStatus: userData.data.registrationStatus,
        roles: userData.data.roles[0],
      };
      dispatch(loginHandler(authDetails));
    }

    !data && navigate("/");

    if (data?.roles) {
      data?.roles === "user" && navigate("/home");
    }
  }, [pathname]);

  const handleClick = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "Engage_Tap_Logo",
        category: "User Interaction",
        action: "Button Click",
        label: "Logo Button",
      });
    }
    window.location.reload();
  };

  return (
    <div className="sticky top-0 w-full h-[10vh] overflow-hidden bg-gray-900 px-[2vw] py-[2vh] flex justify-between z-50">
      <img
        src="/img/logoDark.svg"
        alt="logo"
        className="h-auto min-h-[50px] w-auto object-contain cursor-pointer"
        onClick={() => handleClick()}
        // role="presentation"
      />
      <div className="flex w-max gap-2">
        <ButtonV4 color="blue" setActive={navigation}>
          User Dashboard
        </ButtonV4>
      </div>
    </div>
  );
}
