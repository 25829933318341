import apiSlice from "../../features/apiSlice";
import { removeEmptyKeys } from "../../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/login",
        method: "POST",
        body: credentials,
      }),
    }),
    register: builder.mutation({
      query: (credentials) => ({
        url: "/auth/register",
        method: "POST",
        body: credentials,
      }),
    }),
    verifyOtp: builder.mutation({
      query: (credentials) => ({
        url: "/auth/verifyOtp",
        method: "POST",
        body: credentials,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (credentials) => ({
        url: "/auth/forgotPassword",
        method: "POST",
        body: credentials,
      }),
    }),
    verifyForgotPassword: builder.mutation({
      query: (credentials) => ({
        url: "/auth/verifyForgotPassword",
        method: "POST",
        body: credentials,
      }),
    }),
    changeForgotPassword: builder.mutation({
      query: (credentials) => ({
        url: "/auth/changeForgotPassword",
        method: "POST",
        body: credentials,
      }),
    }),
  }),
});

export const {
  // useGetUserQuery,
  useLoginMutation,
  useRegisterMutation,
  useVerifyOtpMutation,
  useForgotPasswordMutation,
  useVerifyForgotPasswordMutation,
  useChangeForgotPasswordMutation,
} = authApiSlice;
