import { FormikValues } from "formik";
import { ChangeEvent, useState } from "react";
import { useUploadVideoMutation } from "../../api/fileUpload";
import { FormHelperText } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface props {
  name: string;
  label?: string;
  type: string;
  placeholder?: string;
  formik?: FormikValues;
  value?: string;
  width?: string;
  handleChange?: Function;
  className?: string;
}

interface props2 {
  name: string;
  label?: string;
  type: string;
  accept?: string;
  formik: FormikValues;
  value?: string;
  width?: string;
  handleChange?: Function;
}

export function InputField({
  label,
  type,
  placeholder,
  formik,
  name,
  width,
  className,
}: props) {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      className={`flex flex-col ${width ? "w-[47%]" : "w-[99%]"} gap-1 mb-4 `}
    >
      {label && (
        <label className="font-semibold text-sm capitalize">{label}</label>
      )}
      <div className={`flex gap-1 w-full pl-0.5 ${className ?? ""}`}>
        <input
          name={name}
          type={!showPassword ? type : "text"}
          placeholder={placeholder}
          className={`p-2 rounded-xl border ${
            type !== "password" ? "w-full" : "w-11/12"
          }`}
          // defaultValue={formik && formik?.values[`${name}`]}
          value={formik && formik?.values[`${name}`]}
          onChange={formik && formik.handleChange}
          onBlur={formik && formik.handleBlur}
        />
        {type === "password" && (
          <button
            aria-label="toggle password visibility"
            onClick={togglePasswordVisibility}
            className="w-1/12"
            type="button"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </button>
        )}
      </div>
      {formik && formik.touched[name] && formik.errors[name] && (
        <FormHelperText error className="pl-1">
          {formik.errors[name]}
        </FormHelperText>
      )}
    </div>
  );
}

export function InputFieldV2({
  label,
  type,
  placeholder,
  handleChange,
  name,
  value,
}: props) {
  return (
    <div className="flex flex-col w-full gap-1 mb-4">
      {label && <label className="font-semibold text-sm ">{label}</label>}
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        className="p-2 border rounded-xl "
        defaultValue={value && value}
        onChange={(e) => handleChange && handleChange(e.target.value)}
      />
    </div>
  );
}

export function FileField({
  label,
  type,
  accept,
  formik,
  name,
  width,
}: props2) {
  // console.log(formik?.values);

  const [videoUrl, setVideoUrl] = useState(formik.values.videoLink || "");
  const [thumbs, setThumbs] = useState(formik.values.thumbs || [""]);

  const [upload, { isLoading: uploadIsLoading, isSuccess: uploadSuccess }] =
    useUploadVideoMutation({});

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target.files && event?.target?.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("video", file);
      formData.append("fileName", formik.values.videFileName);

      upload(formData)
        .unwrap()
        .then((res) => {
          res.data.screenshots &&
            formik.setFieldValue("thumbImage", res.data.screenshots[0]);
          res.data.screenshots &&
            formik.setFieldValue("thumbs", res.data.screenshots);
          res.data.videoUrl &&
            formik.setFieldValue("videoLink", res.data.videoUrl);
          res.data.screenshots && setThumbs(res.data.screenshots);
          res.data.videoUrl && setVideoUrl(res.data.videoUrl);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className={`flex flex-col ${width ? "w-[47%]" : "w-full"} gap-1 mb-4`}>
      {label && (
        <label className="font-semibold text-sm capitalize">{label}</label>
      )}
      <div className="flex flex-col justify-center">
        <input
          name={name}
          type={type}
          accept={accept}
          className="p-2 border rounded-xl h-max"
          onChange={handleFileChange}
        />
        <div className="m-auto flex flex-wrap">
          {uploadIsLoading
            ? "Uploading..."
            : videoUrl !== "" && (
                <video
                  controls
                  style={{
                    maxWidth: "250px",
                    maxHeight: "250px",
                    marginTop: "10px",
                  }}
                  src={videoUrl}
                />
              )}
        </div>
      </div>
      <div className="flex justify-between gap-4 flex-col">
        {thumbs.length !== 0 && thumbs[0] !== "" && (
          <h1 className="font-semibold">Thumbnails:</h1>
        )}
        <div className="flex flex-wrap gap-4 justify-center">
          {thumbs[0] !== "" &&
            thumbs.map((item: string) => (
              <img
                src={item}
                alt="Selected"
                style={{
                  maxWidth: "200px",
                  maxHeight: "200px",
                  marginTop: "10px",
                }}
              />
            ))}
        </div>
      </div>
      {formik && formik.touched[name] && formik.errors[name] && (
        <FormHelperText error className="pl-1">
          {formik.errors[name]}
        </FormHelperText>
      )}
    </div>
  );
}
