import apiSlice from "../../features/apiSlice";
import { removeEmptyKeys } from "../../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getKeyword: builder.query({
      query: (queries) => {
        return {
          url: '/keyword',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['Keyword']
    }),
    createKeyword: builder.mutation({
      query: (credentials) => ({
        url: "/keyword",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Keyword']
    }),
    updateKeyword: builder.mutation({
      query: (credentials) => ({
        url: `/keyword/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags:['Keyword']
    }),
    bulkUpdateKeyword: builder.mutation({
      query: (credentials) => ({
        url: `/keyword/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Keyword']
    }),
    deleteKeyword: builder.mutation({
      query: (credentials) => ({
        url: `/keyword/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Keyword']
    }),
    hardDeleteKeyword: builder.mutation({
      query: (credentials) => ({
        url: `/keyword/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Keyword']
    }),
  }),
});

export const {
  useGetKeywordQuery,
  useCreateKeywordMutation,
  useUpdateKeywordMutation,
  useBulkUpdateKeywordMutation,
  useDeleteKeywordMutation,
  useHardDeleteKeywordMutation
} = authApiSlice;
