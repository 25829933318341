import apiSlice from "../../features/apiSlice";
import { removeEmptyKeys } from "../../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getYear: builder.query({
      query: (queries) => {
        return {
          url: '/year',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['Year']
    }),
    createYear: builder.mutation({
      query: (credentials) => ({
        url: "/year",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Year']
    }),
    updateYear: builder.mutation({
      query: (credentials) => ({
        url: `/year/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags:['Year']
    }),    
    bulkUpdateYear: builder.mutation({
      query: (credentials) => ({
        url: `/year/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Year']
    }),
    deleteYear: builder.mutation({
      query: (credentials) => ({
        url: `/year/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Year']
    }),
    hardDeleteYear: builder.mutation({
      query: (credentials) => ({
        url: `/year/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Year']
    }),
  }),
});

export const {
  useGetYearQuery,
  useCreateYearMutation,
  useUpdateYearMutation,
  useBulkUpdateYearMutation,
  useDeleteYearMutation,
  useHardDeleteYearMutation
} = authApiSlice;
