import { Box, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import { SelectBoxV2 } from "../atoms/selectBox";
import { InputField } from "../atoms/inputField";
import { ButtonV1 } from "../atoms/button";
import { useUpdateUserMutation } from "../../api/users/users";
import snackbar from "../../api/snackbar/useSnackbar";
import { useFormik } from "formik";
import { useState } from "react";

interface Item {
  _id: string;
  fullName: string;
  email: string;
  roles: string[];
  active: boolean;
}

interface ItemV2 {
  fullName: string | undefined;
  email: string | undefined;
  roles: (string | undefined)[];
  active: boolean;
  password?: string | undefined;
}

interface props {
  open: boolean;
  handleClose: Function;
  item: Item | undefined;
}

export function UserModel({ open, handleClose, item }: props) {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "max-content",
    minWidth: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 4,
    p: 4,
  };
  const [password, setPassword] = useState("");
  const [update, { isLoading: updateIsLoading, isSuccess: updateSuccess }] =
    useUpdateUserMutation({});

  const Formik = useFormik({
    initialValues: {
      fullName: item?.fullName,
      email: item?.email,
      password: "",
      roles: [item?.roles[0]],
      active: item?.active,
    },
    enableReinitialize: false,
    onSubmit: (values) => {
      let body: ItemV2 = {
        fullName: values?.fullName,
        email: values?.email,
        roles: values?.roles,
        active: values?.active || false,
      };

      if(values.password !== ''){
        body.password = values.password
      }

      update({ id: item && item._id, body: body })
        .unwrap()
        .then((res) => {
          console.log(res);
          snackbar.success(res.message);
          handleClose();
        })
        .catch((error: { data: { message: string } }) => {
          console.log(error);
          snackbar.error(error?.data?.message);
        });
    },
  });

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          className="absolute right-4 top-4 cursor-pointer"
          onClick={() => handleClose()}
        >
          <Close className="" />
        </div>
        <form className="flex flex-col" onSubmit={Formik.handleSubmit}>
          <h1 className="text-lg font-medium mb-4 w-full">Edit SubBrand</h1>
          <InputField
            name="fullName"
            label="Name"
            type="text"
            formik={Formik}
          />
          <InputField name="email" label="Email" type="email" formik={Formik} />
          <InputField
            name="password"
            label="Password"
            type="password"
            formik={Formik}
          />
          <InputField
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            formik={Formik}
          />
          <SelectBoxV2
            value={Formik.values.roles[0]}
            label="Brand"
            handleChange={(e: string) => Formik.setFieldValue("roles", [e])}
          >
            <>
              <option value={"user"}>User</option>
              <option value={"contentCreator"}>Content Creator</option>
              <option value={"admin"}>Admin</option>
            </>
          </SelectBoxV2>
          <ButtonV1 color="blue" type="submit">
            Update
          </ButtonV1>
        </form>
      </Box>
    </Modal>
  );
}
