import { Autocomplete, FormHelperText, TextField } from "@mui/material";
import { FormikValues } from "formik";
import { SyntheticEvent, useEffect, useState } from "react";

interface props {
  label: string;
  value: string | undefined;
  handleChange: Function;
  children: React.ReactNode;
}

interface propsV2 {
  label: string;
  name: string;
  options: {
    id: string;
    title: string;
  }[];
  formik: FormikValues;
}

interface Option {
  id: string;
  title: string;
}

export function SelectBox({ label, options, name, formik }: propsV2) {
  // console.log(name, formik.values[`${name}`]);
  // console.log(name, options);

  const [optionList, setOptionList] = useState([
    {
      id: "1234567890",
      title: "Types",
    },
  ]);

  const handleSelectChange = (
    event: SyntheticEvent<Element, Event>,
    value: Option[] | null
  ) => {
    // console.log("selectedValues", value);
    // console.log(value?.map((item) => item));
    formik &&
      formik.setFieldValue(
        `${name}`,
        value?.map((item) => item.title)
      );
  };

  useEffect(() => {
    if (options) {
      setOptionList(options);
    }
  }, [options]);

  const values = formik.values[`${name}`];
  // console.log(name, values);

  return (
    <div className="flex flex-col gap-1 mb-4 w-[47%]">
      {label && (
        <label className="font-semibold text-sm capitalize">{label}</label>
      )}
      <Autocomplete
        componentName={name}
        multiple={true}
        limitTags={2}
        id="multiple-limit-tags"
        options={optionList}
        getOptionLabel={(option) => option?.title}
        value={options?.filter((item) =>
          values?.includes(item?.title) ? item : false
        )}
        renderInput={(params) => (
          <TextField
            name={name}
            {...params}
            label={label}
            placeholder={label}
          />
        )}
        sx={{ width: "500px" }}
        className="rounded-xl  mt-1"
        onChange={handleSelectChange}
      />
      {formik && formik.touched[name] && formik.errors[name] && (
        <FormHelperText error className="pl-1">
          {formik.errors[name]}
        </FormHelperText>
      )}
    </div>
  );
}

export function SelectBoxV1({ label, options, formik, name }: propsV2) {
  // console.log(name, formik.values[`${name}`]);
  const [optionList, setOptionList] = useState([
    {
      id: "1234567890",
      title: "Types",
    },
  ]);
  const handleSelectChange = (
    event: SyntheticEvent<Element, Event>,
    value: Option | null
  ) => {
    console.log("selectedValues", value);
    // console.log(value.map((item) => item.id));
    formik && formik.setFieldValue(`${name}`, value && value?.title);
  };

  useEffect(() => {
    if (options) {
      setOptionList(options);
    }
  }, [options]);

  // console.log("formik?.values[`${name}`]",  formik.values[`${name}`]);

  const values = formik.values[`${name}`];
  // console.log(values);

  return (
    <div className="flex flex-col gap-1 mb-4 w-[47%]">
      {label && (
        <label className="font-semibold text-sm capitalize">{label}</label>
      )}
      <Autocomplete
        limitTags={2}
        id="multiple-limit-tags"
        options={optionList}
        getOptionLabel={(option) => option?.title}
        value={options?.filter((item) => values === item.title && item)[0]}
        renderInput={(params) => (
          <TextField {...params} label={label} placeholder={label} />
        )}
        sx={{ width: "500px" }}
        className="rounded-xl  mt-1"
        onChange={handleSelectChange}
      />
      {formik && formik.touched[name] && formik.errors[name] && (
        <FormHelperText error className="pl-1">
          {formik.errors[name]}
        </FormHelperText>
      )}
    </div>
  );
}

export function SelectBoxV2({ label, handleChange, value, children }: props) {
  console.log(value);

  return (
    <div className="flex flex-col w-full gap-1 mb-4">
      {label && (
        <label className="font-semibold text-sm capitalize">{label}</label>
      )}
      <select
        defaultValue={value}
        className="p-2 border rounded-xl "
        onChange={(e) => handleChange(e.target.value)}
      >
        {children}
      </select>
    </div>
  );
}
