import * as yup from "yup";
import { useGetDomainLinkQuery } from "../api/filters/domain";
import { useEffect, useState } from "react";

export const useValidationSchemas = () => {
  const { data: DomainLinkData, isSuccess: DomainLinkSuccess } =
    useGetDomainLinkQuery({});

  const [allowedDomain, setAllowedDomain] = useState<string[]>([]);

  useEffect(() => {
    if (DomainLinkSuccess && DomainLinkData) {
      setAllowedDomain(
        DomainLinkData?.data?.data?.map(
          (elem: { title: string }) => elem?.title
        ) || []
      );
    }
  }, [DomainLinkSuccess, DomainLinkData]);

  // Now that allowedDomain is dynamically fetched, we use it in Yup schema
  const RegisterSchema = yup.object().shape({
    fullName: yup.string().required("Name is required"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .test("is-certain-domain", "Invalid domain", function (value) {
        if (!value) return false;
        const domain = value.split("@")[1]; // Extract domain from email
        return allowedDomain.includes(domain);
      })
      .lowercase()
      .required("Email is required"),
    password: yup
      .string()
      .min(
        8,
        "Password must include one capital alphabet and 8 characters long"
      )
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol")
      .required("Required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .min(
        8,
        "Password must include one capital alphabet and 8 characters long"
      )
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol")
      .required("Required"),
  });

  const LoginSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email")
      .test("is-certain-domain", "Invalid domain", (value) => {
        if (!value) return false;
        const domain = value.split("@")[1]; // Extract domain from email
        return allowedDomain.includes(domain);
      })
      .lowercase()
      .required("Email is required"),
    password: yup
      .string()
      .min(
        8,
        "Password must include one capital alphabet and 8 characters long"
      )
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol")
      .required("Required"),
  });

  const VerifyOtpSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email")
      .test("is-certain-domain", "Invalid domain", (value) => {
        if (!value) return false;
        const domain = value.split("@")[1]; // Extract domain from email
        return allowedDomain.includes(domain);
      })
      .lowercase()
      .required("Email is required"),
    mode: yup.string().required(),
    purpose: yup.string().required(),
    code: yup.string().required(),
  });

  const forgotPasswordSchema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .trim()
      .email("Invalid email")
      .test("is-certain-domain", "Invalid domain", (value) => {
        if (!value) return false;
        const domain = value.split("@")[1]; // Extract domain from email
        return allowedDomain.includes(domain);
      })
      .lowercase(),
  });

  const verifyForgotPasswordSchema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .trim()
      .email("Invalid email")
      .test("is-certain-domain", "Invalid domain", (value) => {
        if (!value) return false;
        const domain = value.split("@")[1]; // Extract domain from email
        return allowedDomain.includes(domain);
      })
      .lowercase(),
    code: yup
      .string()
      .required("Code is required")
      .trim()
      .length(6, "Code must be exactly 6 characters"),
  });

  const changeForgotPasswordSchema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .trim()
      .email("Invalid email")
      .test("is-certain-domain", "Invalid domain", (value) => {
        if (!value) return false;
        const domain = value.split("@")[1]; // Extract domain from email
        return allowedDomain.includes(domain);
      })
      .lowercase(),
    password: yup
      .string()
      .min(
        8,
        "Password must include one capital alphabet and 8 characters long"
      )
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol")
      .required("Required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .min(
        8,
        "Password must include one capital alphabet and 8 characters long"
      )
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol")
      .required("Required"),
  });

  return {
    RegisterSchema,
    LoginSchema,
    VerifyOtpSchema,
    forgotPasswordSchema,
    verifyForgotPasswordSchema,
    changeForgotPasswordSchema,
  };
};
