import { createSlice } from "@reduxjs/toolkit";

const initialStoreState = {
  adType: [],
  brand: [],
  category: [],
  country: [],
  feature: [],
  keyword: [],
  language: [],
  orientation: [],
  region: [],
  stage: [],
  subBrand: [],
  creative: [],
  brandCollab: [],
  year: [],
  filters: []
};

const storeSlice = createSlice({
  name: "data",
  initialState: initialStoreState,
  reducers: {

    store(state, action) {
      const {
        adType,
        brand,
        brandCollab,
        category,
        country,
        feature,
        keyword,
        language,
        orientation,
        region,
        stage,
        subBrand,
        creative,
        year,
        filters
      } = action.payload;

      if (adType) {
        state.adType = adType;
      }
      if (brand) {
        state.brand = brand;
      }
      if (category) {
        state.category = category;
      }
      if (country) {
        state.country = country;
      }
      if (feature) {
        state.feature = feature;
      }
      if (keyword) {
        state.keyword = keyword;
      }
      if (language) {
        state.language = language;
      }
      if (orientation) {
        state.orientation = orientation;
      }
      if (region) {
        state.region = region;
      }
      if (stage) {
        state.stage = stage;
      }
      if (subBrand) {
        state.subBrand = subBrand;
      }
      if (creative) {
        state.creative = creative;
      }
      if (brandCollab) {
        state.brandCollab = brandCollab;
      }
      if (year) {
        state.year = year;
      }
      if (filters) {
        state.brandCollab = brandCollab;
      }
    },

    storeClean(state, action) {
      const {
        adType,
        brand,
        category,
        country,
        feature,
        keyword,
        language,
        orientation,
        region,
        stage,
        subBrand,
        creative,
        year,
        brandCollab,
        filters
      } = action.payload;
      if (adType) {
        state.adType = initialStoreState.adType;
      }
      if (brand) {
        state.brand = initialStoreState.brand;
      }
      if (category) {
        state.category = initialStoreState.category;
      }
      if (country) {
        state.country = initialStoreState.country;
      }
      if (feature) {
        state.feature = initialStoreState.feature;
      }
      if (keyword) {
        state.keyword = initialStoreState.keyword;
      }
      if (language) {
        state.language = initialStoreState.language;
      }
      if (orientation) {
        state.orientation = initialStoreState.orientation;
      }
      if (region) {
        state.region = initialStoreState.region;
      }
      if (stage) {
        state.stage = initialStoreState.stage;
      }
      if (subBrand) {
        state.subBrand = initialStoreState.subBrand;
      }
      if (creative) {
        state.creative = initialStoreState.creative;
      }
      if (brandCollab) {
        state.brandCollab = initialStoreState.brandCollab;
      }
      if (year) {
        state.year = initialStoreState.year;
      }
      if (filters) {
        state.filters = initialStoreState.filters;
      }
    },

    cleanCreative(state) {
      state.creative = initialStoreState.creative
    },

    logout(state) {
      state.adType = initialStoreState.adType;
      state.brand = initialStoreState.brand;
      state.brandCollab = initialStoreState.brandCollab;
      state.category = initialStoreState.category;
      state.country = initialStoreState.country;
      state.feature = initialStoreState.feature;
      state.keyword = initialStoreState.keyword;
      state.language = initialStoreState.language;
      state.orientation = initialStoreState.orientation;
      state.region = initialStoreState.region;
      state.stage = initialStoreState.stage;
      state.subBrand = initialStoreState.subBrand;
      state.year = initialStoreState.year;
      state.filters = initialStoreState.filters;
    },
  },
});

export const storeActions = storeSlice.actions;
export default storeSlice.reducer;
