import { Box, Modal } from "@mui/material";
import { ButtonV1 } from "../atoms/button";
import { Close } from "@mui/icons-material";

interface props {
  open: boolean;
  handleClose: Function;
  handleDelete: Function;
  children?: React.ReactNode;
  message?: string;
}

export function DeletePopup({
  open,
  handleClose,
  handleDelete,
  children,
}: props) {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 4,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          className="absolute right-2 top-2 cursor-pointer"
          onClick={() => handleClose()}
        >
          <Close />
        </div>
        <h1 className="mb-8 w-full text-center text-lg">
          {children ? children : "Are sure to delete this item ?"}
        </h1>
        <div className="flex gap-4 px-4">
          <ButtonV1 color="blue" setActive={() => handleClose()}>
            Cancel
          </ButtonV1>
          <ButtonV1 color="red" setActive={() => handleDelete()}>
            Delete
          </ButtonV1>
        </div>
      </Box>
    </Modal>
  );
}

export function Deactivatepopup({
  open,
  handleClose,
  handleDelete,
  message,
}: props) {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 4,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          className="absolute right-2 top-2 cursor-pointer"
          onClick={() => handleClose()}
        >
          <Close />
        </div>
        <h1 className="mb-8 w-full text-center text-lg">
          {message ? message : "Are sure to update this item ?"}
        </h1>
        <div className="flex gap-4 px-4">
          <ButtonV1 color="blue" setActive={() => handleClose()}>
            Cancel
          </ButtonV1>
          <ButtonV1 color="red" setActive={() => handleDelete()}>
            Update
          </ButtonV1>
        </div>
      </Box>
    </Modal>
  );
}
