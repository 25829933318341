import apiSlice from "../../features/apiSlice";
import { removeEmptyKeys } from "../../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFeature: builder.query({
      query: (queries) => {
        return {
          url: '/feature',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['Feature']
    }),
    createFeature: builder.mutation({
      query: (credentials) => ({
        url: "/feature",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Feature']
    }),
    updateFeature: builder.mutation({
      query: (credentials) => ({
        url: `/feature/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags:['Feature']
    }),
    bulkUpdateFeature: builder.mutation({
      query: (credentials) => ({
        url: `/feature/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Feature']
    }),
    deleteFeature: builder.mutation({
      query: (credentials) => ({
        url: `/feature/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Feature']
    }),
    hardDeleteFeature: builder.mutation({
      query: (credentials) => ({
        url: `/feature/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Feature']
    }),
  }),
});

export const {
  useGetFeatureQuery,
  useCreateFeatureMutation,
  useUpdateFeatureMutation,
  useBulkUpdateFeatureMutation,
  useDeleteFeatureMutation,
  useHardDeleteFeatureMutation
} = authApiSlice;
