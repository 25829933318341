import apiSlice from "../features/apiSlice";

const uploadVideoApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    uploadVideo: builder.mutation({
      query: (credientials) => {
        return {
          url: `/s3/videoUpload`,
          method: "POST",
          body: credientials
        };
      },
    }),
    downloadVideo: builder.mutation({
      query: (credientials) => {
        return {
          url: `/s3/videoDownload`,
          method: "POST",
          body: credientials
        };
      },
    }),
  }),
});

export const { useUploadVideoMutation, useDownloadVideoMutation } = uploadVideoApiSlice;
