import apiSlice from "../../features/apiSlice";
import { removeEmptyKeys } from "../../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: (queries) => {
        return {
          url: '/user',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['User']
    }),
    getUserlist: builder.query({
      query: (queries) => {
        return {
          url: '/user/userList',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['User']
    }),
    updateUser: builder.mutation({
      query: (credentials) => ({
        url: `/user/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags:['User']
    }),
    bulkUpdateUsers: builder.mutation({
      query: (credentials) => ({
        url: `/user/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['User']
    }),
    deleteUser: builder.mutation({
      query: (credentials) => ({
        url: `/user/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['User']
    }),
    hardDeleteUsers: builder.mutation({
      query: (credentials) => ({
        url: `/user/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['User']
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUserlistQuery,
  useUpdateUserMutation,
  useBulkUpdateUsersMutation,
  useDeleteUserMutation,
  useHardDeleteUsersMutation
} = authApiSlice;
