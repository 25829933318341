import apiSlice from "../../features/apiSlice";
import { removeEmptyKeys } from "../../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategory: builder.query({
      query: (queries) => {
        return {
          url: '/category',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['Category']
    }),
    createCategory: builder.mutation({
      query: (credentials) => ({
        url: "/category",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Category']
    }),
    updateCategory: builder.mutation({
      query: (credentials) => ({
        url: `/category/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags:['Category']
    }),    
    bulkUpdateCategory: builder.mutation({
      query: (credentials) => ({
        url: `/category/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Category']
    }),
    deleteCategory: builder.mutation({
      query: (credentials) => ({
        url: `/category/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Category']
    }),
    hardDeleteCategory: builder.mutation({
      query: (credentials) => ({
        url: `/category/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Category']
    }),
  }),
});

export const {
  useGetCategoryQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useBulkUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useHardDeleteCategoryMutation
} = authApiSlice;
