import apiSlice from "../../features/apiSlice";
import { removeEmptyKeys } from "../../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBrand: builder.query({
      query: (queries) => {
        return {
          url: '/brand',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['Brand']
    }),
    createBrand: builder.mutation({
      query: (credentials) => ({
        url: "/brand",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Brand']
    }),
    updateBrand: builder.mutation({
      query: (credentials) => ({
        url: `/brand/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags:['Brand']
    }),
    bulkUpdateBrand: builder.mutation({
      query: (credentials) => ({
        url: `/brand/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Brand']
    }),
    deleteBrand: builder.mutation({
      query: (credentials) => ({
        url: `/brand/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Brand']
    }),
    hardDeleteBrand: builder.mutation({
      query: (credentials) => ({
        url: `/brand/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Brand']
    }),
  }),
});

export const {
  useGetBrandQuery,
  useCreateBrandMutation,
  useUpdateBrandMutation,
  useBulkUpdateBrandMutation,
  useDeleteBrandMutation,
  useHardDeleteBrandMutation
} = authApiSlice;
