import { Search } from "@mui/icons-material";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { logoutFilterHandler } from "../../api/store/storeActions";
import { useEffect } from "react";

interface props {
  setRegex?: Function;
  setKeyword?: Function;
  setPageNo?: Function;
  regex?: string;
}

function SearchBar({ setRegex, setKeyword, setPageNo, regex }: props) {
  const dispatch = useDispatch<any>();

  function regexSet(e: { target: { value: string } }) {
    dispatch(logoutFilterHandler());
    setPageNo && setPageNo(1);
    setRegex && setRegex(e.target.value);
    // debouncedOnChange(e);
  }

  useEffect(() => {
    if (regex === "") {
      setKeyword && setKeyword(regex);
    }
  }, [regex]);

  function handleSubmit(e: any) {
    e.preventDefault();    
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_Search_${regex}`,
        category: "User Interaction",
        action: "Button Click",
        label: `Search Button`,
      });
    }
    setKeyword && setKeyword(regex);
  }
  // const debouncedOnChange = debounce(keywordSet, 1750);

  return (
    <div className="w-2/6 min-w-[15rem] h-[5vh] min-h-[2.5rem] overflow-hidden bg-white border rounded-xl my-auto flex items-center justify-between px-4">
      <form onSubmit={(e) => handleSubmit(e)}>
        <input
          type="text"
          className="border-none w-5/6 active:outline-none focus-visible:outline-none"
          onChange={regexSet}
          value={regex}
          placeholder="Seached for.."
        />
      </form>
      <Search className="text-black" style={{ fontSize: "1.5rem" }} />
    </div>
  );
}

export default SearchBar;
