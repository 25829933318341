import { Box, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";

interface props {
  open: boolean;
  handleClose: Function;
  children: React.ReactNode;
}

export function EditModal({ children, open, handleClose }: props) {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 'max-content',
    minWidth: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 4,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          className="absolute right-4 top-4 cursor-pointer"
          onClick={() => handleClose()}
        >
          <Close className="" />
        </div>
        {children}
      </Box>
    </Modal>
  );
}
