import apiSlice from "../../features/apiSlice";
import { removeEmptyKeys } from "../../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubBrand: builder.query({
      query: (queries) => {
        return {
          url: '/subBrand',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['SubBrand']
    }),
    createSubBrand: builder.mutation({
      query: (credentials) => ({
        url: "/subBrand",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['SubBrand']
    }),
    updateSubBrand: builder.mutation({
      query: (credentials) => ({
        url: `/subBrand/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags:['SubBrand']
    }),    
    bulkUpdateSubBrand: builder.mutation({
      query: (credentials) => ({
        url: `/subBrand/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['SubBrand']
    }),
    deleteSubBrand: builder.mutation({
      query: (credentials) => ({
        url: `/subBrand/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['SubBrand']
    }),
    hardDeleteSubBrand: builder.mutation({
      query: (credentials) => ({
        url: `/subBrand/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['SubBrand']
    }),
  }),
});

export const {
  useGetSubBrandQuery,
  useCreateSubBrandMutation,
  useUpdateSubBrandMutation,
  useBulkUpdateSubBrandMutation,
  useDeleteSubBrandMutation,
  useHardDeleteSubBrandMutation
} = authApiSlice;
