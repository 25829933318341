import { authActions } from "./authSlice";
import snackbar from "../snackbar/useSnackbar";
import {
  saveAuthCookie,
  removeAuthCookie
} from "../../utils/storage";

export interface authDetails {

  accessToken: string;
  userId: string;
  email: string | null;
  fullName: string | null;
  active: boolean,
  isEmailVerified: boolean,
  registrationStatus: string,
  roles: string

}
interface config {
  isSession: boolean;
}

const loginHandler = (authDetails: authDetails) => {
  return (dispatch: (arg0: { payload: any; type: "auth/logout" | "auth/login"; }) => void) => {
    saveAuthCookie(authDetails);
    dispatch(
      authActions.login(authDetails)
    );

  };
};

const logoutHandler = (config: config) => {

  return (dispatch: (arg0: { payload: undefined; type: "auth/logout"; }) => void) => {
    console.log("cleaned");
    removeAuthCookie();
    dispatch(authActions.logout());
  };
};

export { loginHandler, logoutHandler };
