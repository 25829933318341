import apiSlice from "../../features/apiSlice";
import { removeEmptyKeys } from "../../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCountry: builder.query({
      query: (queries) => {
        return {
          url: '/country',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['Country']
    }),
    createCountry: builder.mutation({
      query: (credentials) => ({
        url: "/country",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Country']
    }),
    updateCountry: builder.mutation({
      query: (credentials) => ({
        url: `/country/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags:['Country']
    }),
    bulkUpdateCountry: builder.mutation({
      query: (credentials) => ({
        url: `/country/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Country']
    }),
    deleteCountry: builder.mutation({
      query: (credentials) => ({
        url: `/country/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Country']
    }),
    hardDeleteCountry: builder.mutation({
      query: (credentials) => ({
        url: `/country/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Country']
    }),
  }),
});

export const {
  useGetCountryQuery,
  useCreateCountryMutation,
  useUpdateCountryMutation,
  useBulkUpdateCountryMutation,
  useDeleteCountryMutation,
  useHardDeleteCountryMutation
} = authApiSlice;
