import * as yup from "yup";

export const CreativeSchema = yup.object().shape({
  adTypes: yup.array().of(yup.string().trim().required('Required')).min(1, "Select one Field"),
  brandId: yup.string().trim().required('Select one Field'),
  brandCollab: yup.array().of(yup.string().trim()),
  categories: yup.array().of(yup.string().trim().required('Required')).min(1, "Select one Field"),
  countries: yup.array().of(yup.string().trim().required('Required')).min(1, "Select one Field"),
  creativeFormats: yup.array().of(yup.string().trim()),
  features: yup.array().of(yup.string().trim()),
  gDriveLink: yup.string().trim(),
  isPublished: yup.boolean(),
  keywords: yup.array().of(yup.string().trim().required('Required')).min(1, "Select one Field"),
  lang: yup.string().trim().required('Select one Field'),
  newTitle: yup.string().trim().required('Required'),
  orientation: yup.string().trim().required('Select one Field'),
  regionId: yup.string().trim().required('Select one Field'),
  subBrands: yup.array().of(yup.string().trim()),
  specialKeywords: yup.array().of(yup.string().trim()),
  stage: yup.string().trim().required('Select one Field'),
  title: yup.string().trim().required('Required'),
  year: yup.string().trim().required('Select one Field'),
  DT: yup.string().trim(),
  vimeoLink: yup.string().trim(),
  videFileName: yup.string().trim().required('Required'),
  videoLink: yup.string().trim().required('Required'),
  thumbImage: yup.string().trim(),
  thumbs: yup.array().of(yup.string().trim()),
});

