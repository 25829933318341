import { useEffect, useRef, useState } from "react";
import {
  useLazyGetCreativeUserQuery,
} from "../../api/creative";
import { CreativeType } from "../../types/types";
import { useDispatch, useSelector } from "react-redux";
import { ButtonV1 } from "../atoms/button";
import {
  storeCreativeCleanHandler,
  storeHandler,
} from "../../api/store/storeActions";
import { Loader } from "../atoms/loader";
import { UserProductCard } from "../molecules/userCards";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

interface item {
  _id: string;
  title: string;
}

interface itemSchema {
  stage: string;
  title: string;
  thumbLink: string;
  videoLink: string;
}
interface props {
  setLength: Function;
  length: number | undefined;
  regex: string;
  sortType: string;
  sortOrder: Number;
  pageNo: number;
  setPageNo: Function;
  isLoading: boolean;
  setIsLoading: Function;
}

export function UserCardSection({
  setLength,
  isLoading,
  setIsLoading,
  setPageNo,
  sortOrder,
  sortType,
  pageNo,
  length,
  regex,
}: props) {
  const [order, setOrder] = useState(sortOrder);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch<any>();
  const creatives = useSelector((state: any) => state.data.creative);
  const adType = useSelector((state: any) => state.data.adType);
  const brand = useSelector((state: any) => state.data.brand);
  const category = useSelector((state: any) => state.data.category);
  const country = useSelector((state: any) => state.data.country);
  const feature = useSelector((state: any) => state.data.feature);
  const language = useSelector((state: any) => state.data.language);
  const orientation = useSelector((state: any) => state.data.orientation);
  const region = useSelector((state: any) => state.data.region);
  const stage = useSelector((state: any) => state.data.stage);
  const subBrand = useSelector((state: any) => state.data.subBrand);
  const brandCollab = useSelector((state: any) => state.data.brandCollab);
  const year = useSelector((state: any) => state.data.year);

  const [
    getCreative,
    {
      data: creativeData,
      isLoading: isCreativeLoading,
      isFetching: isCreativeFetching,
      isSuccess: isCreativeSuccess,
    },
  ] = useLazyGetCreativeUserQuery();

  useEffect(() => {
    getCreative(
      {
        title: regex,
        pageSize: pageSize,
        pageNo: pageNo,
        sortType: sortType,
        sortOrder: sortOrder,
        adType: adType?.map((item: item) => item.title),
        brand: brand?.map((item: item) => item.title),
        category: category?.map((item: item) => item.title),
        country: country?.map((item: item) => item.title),
        feature: feature?.map((item: item) => item.title),
        language: language?.map((item: item) => item.title),
        orientation: orientation?.map((item: item) => item.title),
        region: region?.map((item: item) => item.title),
        stage: stage?.map((item: item) => item.title),
        subBrand: subBrand?.map((item: item) => item.title),
        brandCollab: brandCollab?.map((item: item) => item.title),
        year: year?.map((item: item) => item.title),
      },
      true
    );
  }, [
    adType,
    brand,
    brandCollab,
    category,
    country,
    feature,
    getCreative,
    language,
    orientation,
    pageNo,
    pageSize,
    regex,
    region,
    sortOrder,
    sortType,
    stage,
    subBrand,
    year,
  ]);

  function increasePageSize() {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_SeeMore`,
        category: "User Interaction",
        action: "Button Click",
        label: `SeeMore Button`,
      });
    }
    setPageNo((prevPageNo: number) => prevPageNo + 1);
  }

  useEffect(() => {
    if (creativeData && pageNo === 1 && !isCreativeFetching && !isCreativeLoading) {
      setIsLoading(false)
      dispatch(storeCreativeCleanHandler());
      const creativeList = creativeData.data.data?.map(
        (item: CreativeType) => ({
          title: item.newTitle || item.title,
          thumbLink: item.thumbImage,
          videoLink: item.videoLink,
          stage: item?.stage || "",
        })
      );
      const data = {
        creative: creativeList,
      };
      dispatch(storeHandler(data));
      setLength(creativeData?.data?.totalCount);
    }
  }, [
    regex,
    creativeData,
    adType,
    brand,
    category,
    country,
    feature,
    language,
    orientation,
    region,
    stage,
    subBrand,
    brandCollab,
    year,
  ]);

  useEffect(() => {
    if (isCreativeSuccess && pageNo !== 1 && !isCreativeFetching && !isCreativeLoading) {
      setIsLoading(false)
      console.log("page add");
      const creativeList = creativeData.data.data?.map(
        (item: CreativeType) => ({
          title: item.newTitle || item.title,
          thumbLink: item.thumbImage,
          videoLink: item.videoLink,
          stage: item?.stage || "",
        })
      );
      const data = {
        creative: [...creatives, ...creativeList],
      };
      dispatch(storeHandler(data));
      setLength(creativeData?.data.totalCount);
    }
    // console.log(pageNo);
  }, [creativeData]);

  useEffect(() => {
    if (isCreativeLoading || isCreativeFetching) {
      pageNo === 1 && setIsLoading(true)
    }
  }, [isCreativeLoading, isCreativeFetching])

  return (
    <>
      <Masonry className="p-4 px-8 min-h-[70vh] relative">
        { isLoading ? (
          <Loader />
        ) : creatives.length !== 0 ? (
          creatives?.map((item: itemSchema, index: number) => (
            <UserProductCard key={index} item={item} />
          ))
        ) : (
          <div className="absolute w-[90%] h-full flex items-center justify-center">
            <p className="text-2xl font-semibold leading-loose tracking-wider text-[#ef4444]">
              No Creative Found!!
            </p>
          </div>
        )}
      </Masonry>
      {!isCreativeLoading && !isCreativeFetching && length && length !== 0 && length > creatives.length ? (
        <div className="flex justify-center">
          <div className="w-max my-4">
            <ButtonV1 color="blue" setActive={() => increasePageSize()}>
              Load More {length - creatives?.length}
            </ButtonV1>
          </div>
        </div>
      ) : (
        <div className="flex justify-center">
          {/* <div className="w-max my-4">
            <ButtonV1 color="red" setActive={() => decreasePageSize()}>
              Load Less
            </ButtonV1>
          </div> */}
        </div>
      )}
    </>
  );
}
