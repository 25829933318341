import { Box, Modal } from "@mui/material";
import ReactPlayer from "react-player";
import CopyToClipboard from "react-copy-to-clipboard";
import { ButtonV1 } from "../../components/atoms/button";
import { FileDownload, Link } from "@mui/icons-material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function VideoPlayer() {
  const [copy, setCopy] = useState(false);
  const [URLSearchParams, SetURLSearchParams] = useSearchParams();
  console.log(URLSearchParams.get("stage"));
  console.log(URLSearchParams.get("videoUrl"));
  console.log(URLSearchParams.get("videoName"));
  let stage = URLSearchParams.get("stage") || "";
  let videoUrl = URLSearchParams.get("videoUrl") || "";
  let videoName = URLSearchParams.get("videoName") || "";

  videoUrl = videoUrl.replaceAll("+", "%2B");
  videoName = videoName.replaceAll("-", " ");

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100vw",
    bgcolor: "#000000",
    border: "none",
    boxShadow: 4,
    p: 4,
  };

  return (
    <Modal
      open={true}
      onClose={() => console.log("Closed")}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        minHeight={"100vh"}
        height={"auto"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        <p className="bg-[#DA1A42] px-4 py-2 absolute top-10 left-10 w-max rounded-2xl font-bold text-lg text-white">
          {stage}
        </p>
        <ReactPlayer
          url={videoUrl}
          playing
          controls
          className="min-h-[80vh] min-w-full max-h-[80vh] bg-white border-2 rounded-2xl overflow-hidden"
        />
        <div className="flex justify-between pt-4 ">
          <h1 className="max-w-2/3 text-wrap whitespace-normal text-lg text-white">
            {videoName}
          </h1>
          <div className="flex gap-4 relative">
            <ButtonV1 color={`${copy ? "red" : "blue"}`}>
              <CopyToClipboard
                text={
                  `${
                    window.location.origin +
                    "/videoplayer?videoUrl=" +
                    videoUrl +
                    "&stage=" +
                    stage +
                    "&videoName=" +
                    videoName
                  }` || "video"
                }
                onCopy={() => setCopy(true)}
              >
                {copy ? (
                  <p className="capitalize">copied</p>
                ) : (
                  <Link className="text-white" />
                )}
              </CopyToClipboard>
            </ButtonV1>
            <ButtonV1 color="blue" type="button">
              <a href={videoUrl} download={videoName} id="videoPlayer">
                <FileDownload className="text-white" />
              </a>
            </ButtonV1>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
