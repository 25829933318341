import apiSlice from "../../features/apiSlice";
import { removeEmptyKeys } from "../../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCreativeFormat: builder.query({
      query: (queries) => {
        return {
          url: '/creativeFormat',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['CreativeFormat']
    }),
    createCreativeFormat: builder.mutation({
      query: (credentials) => ({
        url: "/creativeFormat",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['CreativeFormat']
    }),
    updateCreativeFormat: builder.mutation({
      query: (credentials) => ({
        url: `/creativeFormat/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags:['CreativeFormat']
    }),    
    bulkUpdateCreativeFormat: builder.mutation({
      query: (credentials) => ({
        url: `/creativeFormat/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['CreativeFormat']
    }),
    deleteCreativeFormat: builder.mutation({
      query: (credentials) => ({
        url: `/creativeFormat/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['CreativeFormat']
    }),
    hardDeleteCreativeFormat: builder.mutation({
      query: (credentials) => ({
        url: `/creativeFormat/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['CreativeFormat']
    }),
  }),
});

export const {
  useGetCreativeFormatQuery,
  useCreateCreativeFormatMutation,
  useUpdateCreativeFormatMutation,
  useBulkUpdateCreativeFormatMutation,
  useDeleteCreativeFormatMutation,
  useHardDeleteCreativeFormatMutation
} = authApiSlice;
