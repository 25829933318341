import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import "./selectInput.css"

interface item {
  value: string,
  name: string
}

interface props {
  value: string,
  items: item[],
  onChange: Function | undefined
}

export const SelectInputBox = ({ value, items, onChange }: props) => {
  const [age, setAge] = React.useState(value);

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
    onChange && onChange(event.target.value)
  };

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={age}
      label="filter"
      onChange={handleChange}
      className='capitalize'
    >
      {
        items.map((item: item, index: number) => (
          <MenuItem className='capitalize' key={index} value={item.value}>{item.name}</MenuItem>
        ))
      }
    </Select>
  );
}