import "./style.css";
import { FormikValues } from "formik";
import OTPInput from "react-otp-input";

interface OtpProps {
  formik: FormikValues,
  name: string
}

function OtpVerification({ formik, name }: OtpProps)  {
  return (
    <div className="otpContainer">
      <OTPInput
        inputStyle={{
          width: '4vw', 
          maxWidth: '48px',
          aspectRatio: '1/1',   
          fontSize: '18px',
          borderRadius: '5px',
          border: '1px solid #CCCFD3',
          outline: 'none',  
          backgroundColor: 'white', 
          textAlign: 'center',
          transition: 'background-color 0.3s'
        }}
        numInputs={6}
        value={formik && formik?.values[`${name}`]}
        onChange={(e) => formik.setFieldValue(`${name}`, e)}
        renderInput={(props) => <input  {...props} type="number" name={name} inputMode="numeric" maxLength={1} max={1} pattern="\d*"/>}
      />
      {!!formik.touched[`${name}`] && formik.errors[`${name}`] && (
        <p className="py-1 text-[#F45B69] w-full text-left">
          {formik.errors[`${name}`]}
        </p>
      )}
   </div>
  );
}

export default OtpVerification;
