import apiSlice from "../features/apiSlice";
import { removeEmptyKeys } from "../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    bulkImport: builder.mutation({
      query: (credentials) => ({
        url: "/exportImport/service/import",
        method: "POST",
        body: credentials,
      }),
    }),
    bulkExport: builder.mutation({
      query: (queries) => ({
        url: "/exportImport/service/export",
        queries: removeEmptyKeys(queries),
        method: "GET"
      }),
    }),
  }),
});

export const {
  useBulkImportMutation,
  useBulkExportMutation
} = authApiSlice;
