import { SelectInputBox } from "./selectInput";

interface props {
  length: Number | undefined,
  setOrder: Function
  isLoading: boolean
}

export function CreativeFilter({ length, setOrder, isLoading }: props) {

  const handleClick = (name: string) => {
    setOrder(name)
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_Sort_${name}`,
        category: "User Interaction",
        action: "Button Click",
        label: `Sort ${name} Button`,
      });
    }
  };
  return (
    <div className="w-full p-4 px-8 flex justify-between whitespace-nowrap flex-wrap">
      <h1 className="text-[1.4rem] font-semibold">
        {isLoading ? <></> : (length ? `Found ${length} creatives` : "All Creatives")}
      </h1>
      <p className="capitalize flex gap-2 items-center border-b-2 pl-4">
        sort :
        <SelectInputBox
          items={[
            { value: "-1", name: "most recent" },
            { value: "1", name: "oldest" }
          ]}
          value="-1"
          onChange={handleClick}
        />
        {/* <select className=" border-none p-2 focus:outline-none bg-transparent"
          onChange={(e) => { handleClick(e.target.value); setOrder(e.target.value) }}>
          <option value={-1}>Most Recent</option>
          <option value={1}>Oldest</option>
        </select> */}
      </p>
    </div>
  );
}
