import _ from "lodash";

const removeEmptyKeys = (data, exclude = []) => {
  const entries = Object.entries(data).filter(
    ([key, value]) =>
      !exclude.includes(key) &&
      value !== null &&
      value !== undefined &&
      value !== ""
  );

  const clean = entries?.map(([key, v]) => {
    const value =
      typeof v == "object" && !Array.isArray(v) ? removeEmptyKeys(v) : v;
    return [key, value];
  });

  return Object.fromEntries(clean);
};

const removeNullishKeys = (data, exclude = []) => {
  const entries = Object.entries(data).filter(
    ([key, value]) => !exclude.includes(key) && value
  );

  const clean = entries?.map(([key, v]) => {
    const value = typeof v == "object" ? removeEmptyKeys(v) : v;
    return [key, value];
  });

  return Object.fromEntries(clean);
};

export { removeEmptyKeys, removeNullishKeys };

export const isHttpValid = (str) => {
  try {
    const newUrl = new URL(str);
    return newUrl.protocol === "http:" || newUrl.protocol === "https:";
  } catch (err) {
    return false;
  }
};

