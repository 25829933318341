import apiSlice from "../../features/apiSlice";
import { removeEmptyKeys } from "../../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDtLevel: builder.query({
      query: (queries) => {
        return {
          url: "/dtLables",
          params: removeEmptyKeys(queries),
        };
      },
      providesTags: ["DtLevel"],
    }),
    createDtLevel: builder.mutation({
      query: (credentials) => ({
        url: "/dtLables",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["DtLevel"],
    }),
    updateDtLevel: builder.mutation({
      query: (credentials) => ({
        url: `/dtLables/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags: ["DtLevel"],
    }),
    bulkUpdateDtLevel: builder.mutation({
      query: (credentials) => ({
        url: `/dtLables/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["DtLevel"],
    }),
    deleteDtLevel: builder.mutation({
      query: (credentials) => ({
        url: `/dtLables/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags: ["DtLevel"],
    }),
    hardDeleteDtLevel: builder.mutation({
      query: (credentials) => ({
        url: `/dtLables/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags: ["DtLevel"],
    }),
  }),
});

export const {
  useGetDtLevelQuery,
  useCreateDtLevelMutation,
  useUpdateDtLevelMutation,
  useBulkUpdateDtLevelMutation,
  useDeleteDtLevelMutation,
  useHardDeleteDtLevelMutation,
} = authApiSlice;
