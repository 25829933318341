import "./App.css";
import Router from "./navigation/Routes";
import { AnimatePresence } from "framer-motion";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./api/snackbar/useSnackbar";
import store from "./features/store";
import { initGA, logPageView } from "./analytics";

import {
  SuccessMessage,
  ErrorMessage,
  WarningMessage,
  InfoMessage,
} from "./components/molecules/snackbar";
import { useEffect } from "react";

function App() {
  // const location = useLocation();

  useEffect(() => {
    initGA();
    logPageView();
  }, []);

  useEffect(() => {
    logPageView();
  }, [window.location.pathname]);

  return (
    <AnimatePresence initial={false}>
      <Provider store={store}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          Components={{
            success: SuccessMessage,
            error: ErrorMessage,
            warning: WarningMessage,
            info: InfoMessage,
          }}
          maxSnack={3}
          autoHideDuration={2000}
        >
          <SnackbarUtilsConfigurator />
          <Router />
        </SnackbarProvider>
      </Provider>
    </AnimatePresence>
  );
}

export default App;
