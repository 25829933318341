import { ChevronRightRounded, Group, Sort } from "@mui/icons-material";
import { ReactChild, ReactElement, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FilterItem } from "../atoms/filterItems";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import { FiltersType } from "../../types/types";
import { useSelector } from "react-redux";

interface props {
  child: string;
  data: FiltersType[];
  handleChange?: Function;
  reduxData: {
    _id: string;
    title: string;
  }[];
  icon: ReactElement;
}

export function FilterTab({
  child,
  data,
  handleChange,
  reduxData,
  icon,
}: props) {

  const [active, setActive] = useState(false);
  const regions = useSelector((state: any) => state.data.region);
  const brands = useSelector((state: any) => state.data.brand);

  useEffect(() => {
    reduxData.length === 0 && setActive(false)
  }, [reduxData])

  const handleClick = (name: string) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_${name}`,
        category: "User Interaction",
        action: "Button Click",
        label: `${name} Drop Button`,
      });
    }
  }

  let dataSet = data

  if (child === "Country") {
    const regionIds = regions.map((region: { title: string; }) => region.title);
    dataSet = data.filter(country => regionIds.includes(country.regionId));
  }

  if (child === "SubBrand") {
    const brandIds = brands.map((brand: { title: string; }) => brand.title);
    dataSet = data.filter(subBrand => brandIds.includes(subBrand.brandId));
  }

  return (
    <div className="flex flex-col pb-2">
      <div
        className="flex justify-between items-center"
        onClick={() => { handleClick(child); setActive(!active) }}
      >
        <p className="font-semibold text-md flex gap-2 items-center">
          <>{icon}</>
          {child}
        </p>
        <div className={`iconEffect ${active ? "iconRotate" : ""}`}>
          <ChevronRightRounded />
        </div>
      </div>
      <ul
        className={`w-full pt-2 overflow-hidden ${active ? "heightTransition" : "heightTransitionV2"
          } active `}
      >
        {dataSet?.map((item) => (
          <FilterItem
            key={item._id}
            id={item._id}
            reduxData={reduxData}
            handleChange={handleChange}
          >
            {item.title}
          </FilterItem>
        ))}
      </ul>
    </div>
  );
}

interface propsAdmin {
  href: string;
  children: string;
}

export function FilterTabV2({ href, children }: propsAdmin) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  return (
    <div
      className="flex justify-between items-center pb-4 cursor-pointer"
      onClick={() => navigate(href)}
    >
      <p
        className={` text-gray-500 flex gap-2 items-center hover:text-white tracking-wide capitalize ${pathname === href && "text-white"
          }`}
      >
        {children === "Users" ? (
          <Group style={{ fontSize: "16px" }} />
        ) : (
          <Sort style={{ fontSize: "16px" }} />
        )}
        {children}
      </p>
    </div>
  );
}
