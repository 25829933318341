/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import AdminLayout from "../../../components/organisms/adminLayout";
import { SelectBoxV1 } from "../../../components/atoms/selectBox";
import { FiltersType } from "../../../types/types";
import { useFormik } from "formik";
import { ButtonV1 } from "../../../components/atoms/button";
import { useGetBrandQuery } from "../../../api/filters/brand";
import { useGetFeatureQuery } from "../../../api/filters/feature";
import { BulkKeywordUpdateSchema } from "../../../validationSchema/bulkKeywordUpdateSchema";
import { InputField } from "../../../components/atoms/inputField";
import { useBulkKeywordUpdateMutation } from "../../../api/bulkKeywordUpdate";
import snackbar from "../../../api/snackbar/useSnackbar";

const BulkKeywordUpdatePage: React.FC = () => {
  const { data: BrandData } = useGetBrandQuery({
    sortType: "title",
    sortOrder: 1,
  });

  const { data: FeatureData } = useGetFeatureQuery({
    sortType: "title",
    sortOrder: 1,
  });

  const [bulkKeywordUpdate] = useBulkKeywordUpdateMutation();

  const Formik = useFormik({
    initialValues: {
      type: "brand",
      key: "",
      keyword: "",
    },
    enableReinitialize: false,
    validationSchema: BulkKeywordUpdateSchema,
    onSubmit: (values, { resetForm }) => {
      bulkKeywordUpdate(values)
        .unwrap()
        .then((res) => {
          console.log(res);
          snackbar.success(res.message);
          resetForm();
        })
        .catch((error) => {
          console.log(error);
          snackbar.error(error?.data?.message);
        });
    },
  });

  const optionData: FiltersType[] = useMemo(() => {
    return Formik.values.type === "brand"
      ? BrandData?.data?.data
      : FeatureData?.data?.data;
  }, [Formik.values.type]);

  return (
    <AdminLayout>
      <div className="flex justify-between w-full pb-4 ">
        <p className="text-[2rem] font-semibold flex flex-col capitalize">
          Bulk Keyword Update
        </p>
      </div>
      <form noValidate onSubmit={Formik.handleSubmit}>
        <div className="flex items-center mb-4">
          <input
            id="brand"
            type="radio"
            name="type"
            value="brand"
            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
            onChange={Formik.handleChange}
            checked={Formik.values.type === "brand"}
          />
          <label
            htmlFor="brand"
            className="block ms-2  text-sm font-medium text-gray-900"
          >
            Brand
          </label>
        </div>

        <div className="flex items-center mb-4">
          <input
            id="feature"
            type="radio"
            name="type"
            value="feature"
            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
            onChange={Formik.handleChange}
            checked={Formik.values.type === "feature"}
          />
          <label
            htmlFor="feature"
            className="block ms-2 text-sm font-medium text-gray-900"
          >
            Feature
          </label>
        </div>

        <div className="flex gap-4 items-start mt-8">
          <SelectBoxV1
            formik={Formik}
            label={Formik.values.type === "brand" ? "Brand" : "Feature"}
            name="key"
            options={optionData?.map((item: FiltersType) => ({
              id: item._id,
              title: item.title,
            }))}
          />
          <InputField
            width="half"
            formik={Formik}
            label="Keyword"
            name="keyword"
            type="text"
            placeholder=""
            className="mt-1"
          />
          <ButtonV1 color="red" className="w-fit mt-7" type="submit">
            Submit
          </ButtonV1>
        </div>
      </form>
    </AdminLayout>
  );
};

export default BulkKeywordUpdatePage;
