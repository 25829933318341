interface props {
  children: string;
  handleChange?: Function;
  id: string;
  reduxData: {
    _id: string,
    title: string
  }[]
}

export function FilterItem({ id, children, handleChange, reduxData }: props) {
  // console.log(children, reduxData.some((item) => item._id === id));
  
  return (
    <li className="list-none text-left p-2 flex items-center gap-2 font-medium text-sm">
      <input type="checkbox"
      checked={reduxData?.some((item) => item._id === id)} 
      // checked={}
      onChange={()=>handleChange && handleChange({_id: id, title: children})} />
      <label>{children}</label>
    </li>
  );
}
