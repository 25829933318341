import apiSlice from "../features/apiSlice";
import { removeEmptyKeys } from "../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCreative: builder.query({
      query: (queries) => {
        return {
          url: '/creative',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['Creative']
    }),
    getCreativeUser: builder.query({
      query: (queries) => {
        return {
          url: '/creative/filterCreative',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['Creative']
    }),
    createCreative: builder.mutation({
      query: (credentials) => ({
        url: "/creative",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Creative']
    }),
    updateCreative: builder.mutation({
      query: (credentials) => ({
        url: `/creative/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags:['Creative']
    }),
    bulkUpdateCreative: builder.mutation({
      query: (credentials) => ({
        url: `/creative/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Creative']
    }),
    deleteCreative: builder.mutation({
      query: (credentials) => ({
        url: `/creative/${credentials.id}`,
        method: "DELETE"
      }),
      invalidatesTags:['Creative']
    }),
    hardDeleteCreative: builder.mutation({
      query: (credentials) => ({
        url: `/creative/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Creative']
    }),
  }),
});

export const {
  useGetCreativeQuery,
  useGetCreativeUserQuery,
  useLazyGetCreativeUserQuery,
  useLazyGetCreativeQuery,
  useCreateCreativeMutation,
  useUpdateCreativeMutation,
  useBulkUpdateCreativeMutation,
  useDeleteCreativeMutation,
  useHardDeleteCreativeMutation
} = authApiSlice;
