import { useEffect, useState } from "react";
import { ButtonV1, ButtonV2, ButtonV3 } from "../../components/atoms/button";
import { InputField } from "../../components/atoms/inputField";
import { useNavigate } from "react-router-dom";
import {
  useLoginMutation,
  useRegisterMutation,
  useVerifyOtpMutation,
} from "../../api/auth/authApiSlice";
import { useFormik } from "formik";
import { useValidationSchemas } from "../../validationSchema/profileSchema";
import snackbar from "../../api/snackbar/useSnackbar";
import { useDispatch } from "react-redux";
import { authDetails, loginHandler } from "../../api/auth/authActions";
import { getAuthFromLocal, saveToLocal } from "../../utils/storage";
import OtpVerification from "../../components/molecules/otpVerification/OtpVerification";

function AuthDashboard() {
  const { LoginSchema, RegisterSchema, VerifyOtpSchema } =
    useValidationSchemas();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [active, setActive] = useState("login");

  let data: authDetails = getAuthFromLocal();

  useEffect(() => {
    data && navigate("/home");
  }, [data]);

  const [login] = useLoginMutation();

  const [register] = useRegisterMutation();

  const [verify] = useVerifyOtpMutation();

  const Login = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "Engage_Tap_Login_Submit",
          category: "User Interaction",
          action: "Button Click",
          label: "Login Submit Button",
        });
      }
      login(values)
        .unwrap()
        .then((res: { data: any; message: string }) => {
          // console.log(res);
          let authDetails = {
            accessToken: res.data.authToken,
            userId: res.data.user._id,
            email: res.data.user.email,
            fullName: res.data.user.fullName,
            active: res.data.user.active,
            isEmailVerified: res.data.user.isEmailVerified,
            registrationStatus: res.data.user.registrationStatus,
            roles: res.data.user.roles[0],
          };
          saveToLocal("accessToken", res.data.authToken);
          dispatch(loginHandler(authDetails));
          snackbar.success(res.message);
          navigate("/home");
        })
        .catch((error: { data: { message: string } }) => {
          console.log(error);
          snackbar.error(error?.data?.message);
        });
    },
  });

  const Register = useFormik({
    initialValues: {
      email: "",
      password: "",
      fullName: "",
      confirmPassword: "",
    },
    enableReinitialize: true,
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "Engage_Tap_Register_Submit",
          category: "User Interaction",
          action: "Button Click",
          label: "Register Submit Button",
        });
      }
      register(values)
        .unwrap()
        .then((res: { data: any; message: string }) => {
          console.log(res);
          saveToLocal("accessToken", res.data.authToken);
          snackbar.success(res.message);
          setActive("otp");
        })
        .catch((error: { data: { message: string } }) => {
          console.log(error);
          snackbar.error(error?.data?.message);
        });
    },
  });

  const VerifyOtp = useFormik({
    initialValues: {
      mode: "email",
      purpose: "registration",
      email: Register.values.email || "",
      code: "",
    },
    enableReinitialize: true,
    validationSchema: VerifyOtpSchema,
    onSubmit: (values) => {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "Engage_Tap_OTP_Verify",
          category: "User Interaction",
          action: "Button Click",
          label: "OTP Verify Button",
        });
      }
      verify(values)
        .unwrap()
        .then((res: { data: any; message: string }) => {
          console.log(res);

          let authDetails = {
            accessToken: res.data.authToken,
            userId: res.data.updatedUser._id,
            email: res.data.updatedUser.email,
            fullName: res.data.updatedUser.fullName,
            active: res.data.updatedUser.active,
            isEmailVerified: res.data.updatedUser.isEmailVerified,
            registrationStatus: res.data.updatedUser.registrationStatus,
            roles: res.data.updatedUser.roles[0],
          };

          saveToLocal("accessToken", res.data.authToken);
          dispatch(loginHandler(authDetails));
          snackbar.success(res.message);
          setActive("login");
          navigate("/home");
        })
        .catch((error: { data: { message: string } }) => {
          console.log(error);
          snackbar.error(error?.data?.message);
        });
    },
  });

  function navigateForgot() {
    handleClick("Forgot");
    navigate("/forgotPassword");
  }

  const handleClick = (name: string) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_${name}`,
        category: "User Interaction",
        action: "Button Click",
        label: `${name} Button`,
      });
    }
  };

  return (
    <div className="relative w-screen h-screen overflow-hidden flex justify-center items-center list-disc">
      <div
        className={`bg-white w-1/4 max-h-4/6 h-auto min-w-[20rem] md:min-w-[25rem] min-[1000px]:min-w-[25rem] overflow-y-scroll border-t rounded-xl shadow-lg shadow-slate-300 px-[2.5vw] py-[5vh] flex justify-between items-center flex-col effect list-disc
        heightTran`}
      >
        <img
          src="/img/logo.svg"
          alt="logo"
          className="h-auto w-4/6 min-w-[10rem] mb-8"
        />
        {active == "login" ? (
          <form
            noValidate
            onSubmit={Login.handleSubmit}
            className={`w-full flex flex-col gap-1 fadeEffect heightTran ${
              active == "login" ? "opacity-100 expanded" : "opacity-0"
            } `}
          >
            <InputField
              name="email"
              formik={Login}
              label="Email"
              type="email"
              placeholder="eg: abc@mail.com"
            />
            <InputField
              name="password"
              formik={Login}
              label="Password"
              type="password"
              placeholder="Password"
            />
            <ButtonV1 type="submit" color="blue">
              Login
            </ButtonV1>
            <ButtonV3
              color="blue"
              type="button"
              setActive={() => navigateForgot}
            >
              forget password ?
            </ButtonV3>
            <ButtonV2
              color="blue"
              type="button"
              setActive={() => {
                handleClick("Register");
                setActive("register");
              }}
            >
              Register
            </ButtonV2>
          </form>
        ) : active == "register" ? (
          <form
            noValidate
            onSubmit={Register.handleSubmit}
            className={`w-full flex flex-col gap-1 fadeEffect heightTran ${
              active == "register" ? "opacity-100 expanded" : "opacity-0"
            } `}
          >
            <InputField
              name="fullName"
              label="Name"
              type="text"
              placeholder="eg: John"
              formik={Register}
            />

            <InputField
              name="email"
              label="Email"
              type="email"
              placeholder="eg: abc@mail.com"
              formik={Register}
            />

            <InputField
              name="password"
              label="Password"
              type="password"
              placeholder="Password"
              formik={Register}
            />

            <InputField
              name="confirmPassword"
              label="Confirm password"
              type="password"
              placeholder="Confirm password"
              formik={Register}
            />
            <div className="mb-2">
              <ul className="list-disc">
                {" "}
                Password requirements include:
                <li>* 1 capital letter</li>
                <li>* 1 lowercase letter</li>
                <li>* 1 special symbol</li>
                <li>* 1 numeric character</li>
                <li>* There must be 8 characters</li>
              </ul>
            </div>
            <ButtonV1 color="blue" type="submit">
              Register
            </ButtonV1>
            <ButtonV2
              color="blue"
              type="button"
              setActive={() => {
                handleClick("Login");
                setActive("login");
              }}
            >
              Login
            </ButtonV2>
          </form>
        ) : (
          <form
            noValidate
            onSubmit={VerifyOtp.handleSubmit}
            className={`w-full flex flex-col gap-1 fadeEffect heightTran ${
              active === "otp" ? "opacity-100 expanded" : "opacity-0"
            } `}
          >
            <InputField
              name="email"
              label="Email"
              type="email"
              placeholder="Registered email"
              formik={Register}
            />
            <OtpVerification formik={VerifyOtp} name={"code"} />
            <ButtonV1 color="blue" type="submit">
              Login
            </ButtonV1>
          </form>
        )}
      </div>
    </div>
  );
}

export default AuthDashboard;
