import AdminLayout from "../../../components/organisms/adminLayout";
import DataGridBox from "../../../components/organisms/dataGrid";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
} from "@mui/x-data-grid";
import React, { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AdminPageFilters from "../../../components/organisms/adminPageFilters";
import {
  useBulkUpdateSubBrandMutation,
  useCreateSubBrandMutation,
  useDeleteSubBrandMutation,
  useGetSubBrandQuery,
  useUpdateSubBrandMutation,
} from "../../../api/filters/subBrand";
import { useGetBrandQuery } from "../../../api/filters/brand";
import { DeletePopup } from "../../../components/molecules/DeleteModal";
import { EditModal } from "../../../components/molecules/EditModal";
import { InputFieldV2 } from "../../../components/atoms/inputField";
import { ButtonV1 } from "../../../components/atoms/button";
import snackbar from "../../../api/snackbar/useSnackbar";
import { SelectBoxV2 } from "../../../components/atoms/selectBox";

interface Item {
  _id: string;
  title: string;
  brandId: string;
}

function SubBrandPage() {
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [isDelete, setDelete] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [isAddNew, setAddNew] = React.useState(false);
  const [SubBrand, setSubBrand] = React.useState("");
  const [brandId, setBrandId] = React.useState("");
  const [regex, setRegex] = React.useState<string>("");
  const [sortType, setSortType] = React.useState<string>("createdAt");
  const [updateType, setUpdateType] = React.useState<string>("createdAt");
  const [sortOrder, setSortOrder] = React.useState<Number>(-1);
  const [currentId, setCurrentId] = React.useState<GridRowId>("");
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  const {
    data: BrandData,
    isFetching: BrandIsFetching,
    isSuccess: BrandSuccess,
  } = useGetBrandQuery({
    pageSize: 100000,
    pageNo: 1,
    sortType: 'title',
    sortOrder: 1,
  });

  const {
    refetch: SubBrandRefetch,
    data: SubBrandData,
    isFetching: SubBrandIsFetching,
    isSuccess: SubBrandSuccess,
  } = useGetSubBrandQuery({
    searchKeyword: regex,
    pageSize: paginationModel.pageSize,
    pageNo: paginationModel.page + 1,
    sortType: sortType,
    sortOrder: sortType === "title" ? 1 : sortOrder,
  });

  const [update, { isLoading: updateIsLoading, isSuccess: updateSuccess }] =
    useUpdateSubBrandMutation({});

  const [
    bulkUpdateData,
    {
      data: bulkUpdatedData,
      isLoading: isbulkUpdatedLoading,
      isSuccess: isbulkUpdatedSuccess,
    },
  ] = useBulkUpdateSubBrandMutation({});

  const [create, { isLoading: createIsLoading, isSuccess: createSuccess }] =
    useCreateSubBrandMutation({});
  const [
    deleteSubBrand,
    { isLoading: deleteIsLoading, isSuccess: deleteSuccess },
  ] = useDeleteSubBrandMutation({});

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 100  },
    { field: "name", headerName: "SubBrand", width: 250  },
    { field: "brandId", headerName: "Brand", width: 250  },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 180,
      cellClassName: "actions",
      getActions: ({ id }: GridRowParams<any>) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon className="text-blue-400" />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon className="text-red-400" />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    if (SubBrandSuccess && SubBrandData) {
      setRows(
        SubBrandData?.data?.data?.map((item: Item) => ({
          id: item?._id,
          name: item?.title,
          brandId: item?.brandId,
          // brandId: item?.brand[0]?._id,
        }))
      );
    }
  }, [
    SubBrandData,
    SubBrandIsFetching,
    SubBrandSuccess,
    BrandData,
    BrandIsFetching,
    BrandSuccess,
  ]);

  useEffect(() => {
    SubBrandRefetch();
  }, []);

  const handleCreateClick = () => {
    setAddNew(true);
    setBrandId("");
    setCurrentId("");
    setSubBrand("");
  };

  const handleEditClick = (id: GridRowId) => () => {
    setEdit(true);
    setCurrentId(id);
    setBrandId(rows.find((item) => item.id === id)?.brandId);
    setSubBrand(rows.find((item) => item.id === id)?.name);
    console.log(id, rows.find((item) => item.id === id));
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDelete(true);
    setCurrentId(id);
    console.log(id);
  };

  const [selectedRowIds, setSelectedRowIds] = React.useState<GridRowId[]>([]);

  const handleSelectionChange = (newSelection: GridRowId[]) => {
    console.log(newSelection);
    setSelectedRowIds(newSelection);
  };

  function handleCreateNew(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    create({ title: SubBrand, brandId: brandId })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res.message);
        setAddNew(false);
        setBrandId("");
        setCurrentId("");
        setSubBrand("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
      });
  }

  function handleUpdate(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    update({ id: currentId, body: { title: SubBrand, brandId: brandId } })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res.message);
        setEdit(false);
        setBrandId("");
        setCurrentId("");
        setSubBrand("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
      });
  }

  function handleDelete() {
    deleteSubBrand({ id: currentId })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res.message);
        setDelete(false);
        setBrandId("");
        setCurrentId("");
        setSubBrand("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setDelete(false);
      });
  }

  function bulkUpdate() {
    console.log(updateType);

    let data;
    if (updateType === "delete") {
      data = { active: false };
    }
    bulkUpdateData({ id: selectedRowIds, data })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res.message);
        setUpdateType("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setUpdateType("");
      });
  }

  return (
    <AdminLayout>
      <AdminPageFilters
        name="SubBrand"
        qty={SubBrandData?.data?.totalCount}
        setAddNew={handleCreateClick}
        setRegex={setRegex}
        bulkUpdate={bulkUpdate}
        setUpdateType={setUpdateType}
        sortType={sortType}
        setSortType={setSortType}
        regex={regex}
      />
      <DataGridBox
        columns={columns}
        initialRows={rows}
        rowModesModel={rowModesModel}
        selectedRowIds={selectedRowIds}
        paginationModel={paginationModel}
        setRowModesModel={setRowModesModel}
        qty={SubBrandData?.data?.totalCount}
        setPaginationModel={setPaginationModel}
        onSelectionModelChange={handleSelectionChange}
      />
      {isDelete && (
        <DeletePopup
          open={isDelete}
          handleClose={() => setDelete(false)}
          handleDelete={handleDelete}
        />
      )}
      {isEdit && (
        <EditModal open={isEdit} handleClose={() => setEdit(false)}>
          <form className="flex flex-col" onSubmit={(e) => handleUpdate(e)}>
            <h1 className="text-lg font-medium mb-4 w-full">Edit SubBrand</h1>
            <InputFieldV2
              name="title"
              label="SubBrand"
              type="text"
              handleChange={setSubBrand}
              value={SubBrand}
            />
            <SelectBoxV2
              value={brandId}
              label="Brand"
              handleChange={setBrandId}
            >
              <>
                <option value={""}></option>
                {BrandData &&
                  BrandData?.data?.data?.map(
                    (item: { _id: string; title: string }) => (
                      <option value={item.title}>{item.title}</option>
                    )
                  )}
              </>
            </SelectBoxV2>
            <ButtonV1 color="blue" type="submit">
              Update
            </ButtonV1>
          </form>
        </EditModal>
      )}
      {isAddNew && (
        <EditModal open={isAddNew} handleClose={() => setAddNew(false)}>
          <form className="flex flex-col" onSubmit={(e) => handleCreateNew(e)}>
            <h1 className="text-lg font-medium mb-4 w-full">Create SubBrand</h1>
            <InputFieldV2
              name="title"
              label="SubBrand"
              type="text"
              placeholder="SubBrand"
              handleChange={setSubBrand}
            />
            <SelectBoxV2 value="" label="Brand" handleChange={setBrandId}>
              <>
                <option value={""}></option>
                {BrandData &&
                  BrandData?.data?.data?.map(
                    (item: { _id: string; title: string }) => (
                      <option value={item.title}>{item.title}</option>
                    )
                  )}
              </>
            </SelectBoxV2>
            <ButtonV1 color="blue" type="submit">
              Add SubBrand
            </ButtonV1>
          </form>
        </EditModal>
      )}
    </AdminLayout>
  );
}

export default SubBrandPage;
