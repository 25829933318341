import ReactGA from 'react-ga';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || ""; 

export const initGA = (): void => {
  ReactGA.initialize(TRACKING_ID);
};

export const logPageView = (): void => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

export const logEvent = (category: string, action: string): void => {
  if (category && action) {
    ReactGA.event({ category, action });
  }
};
