import apiSlice from "../../features/apiSlice";
import { removeEmptyKeys } from "../../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDomainLink: builder.query({
      query: (queries) => {
        return {
          url: "/domainLinks",
          params: removeEmptyKeys(queries),
        };
      },
      providesTags: ["DomainLink"],
    }),
    createDomainLink: builder.mutation({
      query: (credentials) => ({
        url: "/domainLinks",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["DomainLink"],
    }),
    updateDomainLink: builder.mutation({
      query: (credentials) => ({
        url: `/domainLinks/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags: ["DomainLink"],
    }),
    bulkUpdateDomainLink: builder.mutation({
      query: (credentials) => ({
        url: `/domainLinks/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["DomainLink"],
    }),
    deleteDomainLink: builder.mutation({
      query: (credentials) => ({
        url: `/domainLinks/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags: ["DomainLink"],
    }),
    hardDeleteDomainLink: builder.mutation({
      query: (credentials) => ({
        url: `/domainLinks/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags: ["DomainLink"],
    }),
  }),
});

export const {
  useGetDomainLinkQuery,
  useCreateDomainLinkMutation,
  useUpdateDomainLinkMutation,
  useBulkUpdateDomainLinkMutation,
  useDeleteDomainLinkMutation,
  useHardDeleteDomainLinkMutation,
} = authApiSlice;
