import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logoutHandler } from "../api/auth/authActions";
import snackbar from "../api/snackbar/useSnackbar";
import { getFromLocal, removeAuthCookie } from "../utils/storage";
import { baseUrl } from "../config/constants";

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const accessToken = getFromLocal("accessToken");
    accessToken && headers.set("authorization", `Bearer ${accessToken}`);
    return headers;
  },
});

let isLoggingOut = false;

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (!isLoggingOut && (result?.error?.status === 500 || result?.error?.status === 401 || result?.error?.status === 403)) {
     isLoggingOut = true;

    // Notify user of the expired token or unauthorized state
    snackbar.error(result?.error?.message || "Session Expired!");

    // Dispatch logout action
    api.dispatch(logoutHandler({ isSession: false }));

    // Remove the authentication cookie
    removeAuthCookie();

    // Refresh the page or redirect to login
    window.location.href('/'); // or use: window.location.href = '/login';
    window.location.reload();
  }

  return result;
};

const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    "User",
    "Creative",
    "Category",
    "AdType",
    "Brand",
    "Country",
    "Feature",
    "Filter",
    "Keyword",
    "Language",
    "Orientation",
    "Region",
    "Stage",
    "SubBrand",
    "SpecialKeyword",
    "BrandCollab",
    "CreativeFormat",
    "Year",
    "DtLevel",
    "DomainLink",
    "BulkKeywordUpdate",
  ],
  endpoints: (builder) => ({}),
});

export default apiSlice;
