import apiSlice from "../features/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    bulkKeywordUpdate: builder.mutation({
      query: (credentials) => ({
        url: `/bulkUpdate`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["BulkKeywordUpdate"],
    }),
  }),
});

export const { useBulkKeywordUpdateMutation } = authApiSlice;
