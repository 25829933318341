// CustomRouter.tsx
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import Routes from react-router-dom

// Import your page components
import UserDashboard from "../pages/dashboard/userDashboard";
import AuthDashboard from "../pages/auth/auth";
import VideoPlayer from "../pages/videoPlayer/VideoPlayer";
import CreativePage from "../pages/dashboard/admin/creative";
import AdTypePage from "../pages/dashboard/admin/adType";
import BrandPage from "../pages/dashboard/admin/brand";
import BrandCollabPage from "../pages/dashboard/admin/brandCollab";
import CategoryPage from "../pages/dashboard/admin/category";
import CreativeFormatPage from "../pages/dashboard/admin/creativeFormat";
import FeaturePage from "../pages/dashboard/admin/feature";
import KeywordPage from "../pages/dashboard/admin/keyword";
import LanguagePage from "../pages/dashboard/admin/language";
import OrientationPage from "../pages/dashboard/admin/orientation";
import StagePage from "../pages/dashboard/admin/stage";
import SubBrandPage from "../pages/dashboard/admin/subBrand";
import CountryPage from "../pages/dashboard/admin/country";
import RegionPage from "../pages/dashboard/admin/region";
import UsersPage from "../pages/dashboard/admin/users";
import YearPage from "../pages/dashboard/admin/year";
import ForgotDashboard from "../pages/auth/forgotPassword";
import NotFoundPage from "../NotFoundPage";
import SpecialKeywordPage from "../pages/dashboard/admin/specialKeyword";
import DtLevelsPage from "../pages/dashboard/admin/dtLevel";
import DomainLinkPage from "../pages/dashboard/admin/domainLink";
import BulkKeywordUpdatePage from "../pages/dashboard/admin/bulkKeywordUpdate";

// CustomRouter component
const CustomRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AuthDashboard />} />
        <Route path="/videoPlayer" element={<VideoPlayer />} />
        <Route path="/home" element={<UserDashboard />} />
        <Route path="/forgotPassword" element={<ForgotDashboard />} />
        <Route path="/admin" element={<CreativePage />} />
        <Route path="/admin/users" element={<UsersPage />} />
        <Route path="/admin/adType" element={<AdTypePage />} />
        <Route path="/admin/brand" element={<BrandPage />} />
        <Route path="/admin/brandCollab" element={<BrandCollabPage />} />
        <Route path="/admin/category" element={<CategoryPage />} />
        <Route path="/admin/country" element={<CountryPage />} />
        <Route path="/admin/creativeFormat" element={<CreativeFormatPage />} />
        <Route path="/admin/feature" element={<FeaturePage />} />
        <Route path="/admin/keyword" element={<KeywordPage />} />
        <Route path="/admin/specialKeyword" element={<SpecialKeywordPage />} />
        <Route path="/admin/language" element={<LanguagePage />} />
        <Route path="/admin/orientation" element={<OrientationPage />} />
        <Route path="/admin/region" element={<RegionPage />} />
        <Route path="/admin/stage" element={<StagePage />} />
        <Route path="/admin/subBrand" element={<SubBrandPage />} />
        <Route path="/admin/year" element={<YearPage />} />
        <Route path="/admin/dtLevels" element={<DtLevelsPage />} />
        <Route path="/admin/domain" element={<DomainLinkPage />} />
        <Route
          path="/admin/bulkKeywordUpdate"
          element={<BulkKeywordUpdatePage />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default CustomRouter;
