interface props {
  children: string | React.ReactNode;
  color: string;
  active?: boolean;
  setActive?: Function;
  type?: "button" | "submit" | "reset";
  className?: string;
}

export function ButtonV1({
  children,
  color,
  active,
  setActive,
  type,
  className,
}: props) {
  return (
    <button
      type={type || "button"}
      onClick={() => setActive && setActive()}
      className={`w-full my-1 flex gap-1 justify-center items-center capitalize text-white p-2 px-4 border-none rounded-xl whitespace-nowrap bg-[#DA1A42] hover:bg-[#a90027] ${
        className ?? ""
      }`}
    >
      {children}
    </button>
  );
}

export function ButtonV2({ children, color, active, setActive }: props) {
  return (
    <button
      type="button"
      onClick={() => setActive && setActive()}
      className={`w-full my-1 flex gap-1 justify-center items-center capitalize p-2 px-4 rounded-xl whitespace-nowrap border border-[#DA1A42] text-[#DA1A42] hover:bg-[#a90027] hover:text-white`}
    >
      {children}
    </button>
  );
}

export function ButtonV3({ children, color, active, setActive, type }: props) {
  return (
    <button
      type={type || "button"}
      onClick={setActive && setActive()}
      className={`w-full my-1 flex gap-1 justify-center items-center capitalize p-2 px-4 border-none rounded-xl whitespace-nowrap hover:bg-[#a90027] text-[#DA1A42] hover:text-white`}
    >
      {children}
    </button>
  );
}

export function ButtonV4({ children, color, active, setActive }: props) {
  return (
    <button
      type="button"
      onClick={() => setActive && setActive()}
      className={`w-max my-1 flex gap-1 justify-center items-center capitalize p-2 px-4 border-none rounded-xl whitespace-nowrapl hover:bg-[#a90027] text-[#DA1A42] hover:text-white`}
    >
      {children}
    </button>
  );
}
