import apiSlice from "../../features/apiSlice";
import { removeEmptyKeys } from "../../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrientation: builder.query({
      query: (queries) => {
        return {
          url: '/orientation',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['Orientation']
    }),
    createOrientation: builder.mutation({
      query: (credentials) => ({
        url: "/orientation",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Orientation']
    }),
    updateOrientation: builder.mutation({
      query: (credentials) => ({
        url: `/orientation/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags:['Orientation']
    }),
    bulkUpdateOrientation: builder.mutation({
      query: (credentials) => ({
        url: `/orientation/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Orientation']
    }),
    deleteOrientation: builder.mutation({
      query: (credentials) => ({
        url: `/orientation/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Orientation']
    }),
    hardDeleteOrientation: builder.mutation({
      query: (credentials) => ({
        url: `/orientation/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Orientation']
    }),
  }),
});

export const {
  useGetOrientationQuery,
  useCreateOrientationMutation,
  useUpdateOrientationMutation,
  useBulkUpdateOrientationMutation,
  useDeleteOrientationMutation,
  useHardDeleteOrientationMutation
} = authApiSlice;
