import AdminLayout from "../../../components/organisms/adminLayout";
import DataGridBox from "../../../components/organisms/dataGrid";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridTreeNodeWithRender,
  GridValidRowModel,
} from "@mui/x-data-grid";
import React, { useEffect } from "react";
import snackbar from "../../../api/snackbar/useSnackbar";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Close from "@mui/icons-material/Close";
import AdminPageFilters from "../../../components/organisms/adminPageFilters";
import {
  useBulkUpdateCreativeMutation,
  useDeleteCreativeMutation,
  useGetCreativeQuery,
  useHardDeleteCreativeMutation,
  useUpdateCreativeMutation,
} from "../../../api/creative";
import { CreativeType } from "../../../types/types";
import {
  Deactivatepopup,
  DeletePopup,
} from "../../../components/molecules/DeleteModal";
import {
  AddCreativeModal,
  EditCreativeModal,
} from "../../../components/molecules/CreativeModal";
import { Check, Refresh } from "@mui/icons-material";
import { EditModal } from "../../../components/molecules/EditModal";
import { ButtonV1 } from "../../../components/atoms/button";
import { useGenerateThumbMutation } from "../../../api/thumbGenrator";

function CreativePage() {
  const [currentId, setCurrentId] = React.useState<GridRowId>("");
  const [currentImg, setCurrentImg] = React.useState("");
  const [isEdit, setEdit] = React.useState(false);
  const [isAddNew, setAddNew] = React.useState(false);
  const [isDelete, setDelete] = React.useState(false);
  const [isBulkDelete, setBulkDelete] = React.useState(false);
  const [isDeactivate, setDeactivate] = React.useState(false);
  const [isThumb, setThumb] = React.useState(false);
  const [editItem, setEditItem] = React.useState<CreativeType>();
  const [thumbs, setThumbs] = React.useState<string[]>();
  const [regex, setRegex] = React.useState<string>("");
  const [sortType, setSortType] = React.useState<string>("createdAt");
  const [updateType, setUpdateType] = React.useState<string>("publish");
  const [filterType, setFilterType] = React.useState<string>("active");
  const [sortOrder, setSortOrder] = React.useState<Number>(-1);
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [selectedRowIds, setSelectedRowIds] = React.useState<GridRowId[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });


  const columns: GridColDef[] = [
    { field: "srno", headerName: "Sr no.", width: 80 },
    { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Name", width: 250 },
    {
      field: "img",
      headerName: "Thumb",
      width: 250,
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => (
        <div className="w-[250px] h-[100px] flex justify-center rounded-xl">
          <img
            onClick={handleThumbClick(`${params?.row?.id}`)}
            src={params?.row?.img}
            alt={params?.row?.img}
            style={{ width: "auto", minWidth: "50px", height: "90px", margin: "5px" }} // adjust width and height as needed
            className="cursor-pointer bg-[#EEF2F7]"
          />
        </div>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 180,
      cellClassName: "actions",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon className="text-blue-400" />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.row.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon className="text-red-400" />}
            label="Delete"
            onClick={handleDeleteClick(params.row.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <>
                {params?.row?.isPublished ? (
                  <Check
                    className={`text-white bg-green-500 rounded-full p-0.5`}
                  />
                ) : (
                  <Close
                    className={`text-white bg-yellow-500 rounded-full p-0.5`}
                  />
                )}
              </>
            }
            label="Unpublish"
            onClick={handleDeactivateClick(params.row.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const {
    data: creativeData,
    isLoading: isCreativeLoading,
    isSuccess: isCreativeSuccess,
  } = useGetCreativeQuery({
    title: regex,
    filterType: filterType,
    pageSize: paginationModel.pageSize,
    pageNo: paginationModel.page + 1,
    sortType:
      sortType === "unPublished"
        ? "isPublished"
        : sortType === "published"
          ? "isPublished"
          : sortType,
    sortOrder:
      sortType === "published"
        ? -1
        : sortType === "unPublished"
          ? 1
          : sortType === "newTitle"
            ? 1
            : sortOrder,
  });

  const [
    updateData,
    {
      data: updatedData,
      isLoading: isUpdatedLoading,
      isSuccess: isUpdatedSuccess,
    },
  ] = useUpdateCreativeMutation({});

  const [
    bulkUpdateData,
    {
      data: bulkUpdatedData,
      isLoading: isbulkUpdatedLoading,
      isSuccess: isbulkUpdatedSuccess,
    },
  ] = useBulkUpdateCreativeMutation({});

  const [
    deleteCreative,
    { isLoading: deleteIsLoading, isSuccess: deleteSuccess },
  ] = useDeleteCreativeMutation({});

  const [
    hardDeleteCreative,
    { isLoading: hardDeleteIsLoading, isSuccess: hardDeleteSuccess },
  ] = useHardDeleteCreativeMutation({});

  const [
    generateThumb,
    { isLoading: generateThumbIsLoading, isSuccess: generateThumbSuccess },
  ] = useGenerateThumbMutation({});

  useEffect(() => {
    if (creativeData) {
      setRows(
        creativeData.data.data.map((item: CreativeType, index: number) => ({
          srno: index + paginationModel.page * paginationModel.pageSize + 1,
          id: item._id,
          name: item.newTitle,
          img: item.thumbImage || item.thumbs[0],
          videoLink: item.videoLink,
          isPublished: item.isPublished,
        }))
      );
    }
    // console.log("creativeData", creativeData);
  }, [isCreativeSuccess, creativeData, paginationModel]);

  const handleEditClick = (id: GridRowId) => () => {
    setEdit(true);
    const item = creativeData?.data?.data.filter(
      (item: CreativeType) => item._id === id
    );
    setEditItem(item[0]);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDelete(true);
    setCurrentId(id);
    console.log(id);
  };

  const handleDeactivateClick = (id: GridRowId) => () => {
    setDeactivate(true);
    setCurrentId(id);
    console.log(id);
  };

  const handleThumbClick = (id: GridRowId) => () => {
    setThumb(true);
    const item: CreativeType[] = creativeData?.data?.data.filter(
      (item: CreativeType) => item._id === id
    );
    setCurrentImg(item[0].thumbImage || item[0].thumbs[0]);
    setThumbs(item[0].thumbs || [item[0].thumbImage]);
    setEditItem(item[0]);
  };

  const handleSoftDelete = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap__Change_Status`,
        category: "User Interaction",
        action: "Button Click",
        label: ` Change Status Button`,
      });
    }
    deleteCreative({ id: currentId })
      .unwrap()
      .then((res) => {
        setDeactivate(false);
        snackbar.success(res.message);
        setCurrentId("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setDeactivate(false);
      });
  };

  function handleHardDelete() {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_Delete_Creative`,
        category: "User Interaction",
        action: "Button Click",
        label: `Delete Creative Button`,
      });
    }
    hardDeleteCreative({ id: currentId })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res.message);
        setDelete(false);
        setCurrentId("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setDelete(false);
      });
  }

  function handleBulkDelete() {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_Bulk_Delete_Creative`,
        category: "User Interaction",
        action: "Button Click",
        label: `Delete Creative Button`,
      });
    }
    let data = { active: true };
    bulkUpdateData({ id: selectedRowIds, data })
      .unwrap()
      .then((res) => {
        setBulkDelete(false)
        console.log(res);
        snackbar.success(res.message);
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
      });
  }

  const handleSelectionChange = (newSelection: GridRowId[]) => {
    console.log(newSelection);
    setSelectedRowIds(newSelection);
  };

  function bulkUpdate() {
    let data;
    if (updateType === "delete") {
      setBulkDelete(true)
      return;
    } else if (updateType === "publish") {
      data = { isPublished: true };
    } else if (updateType === "enable") {
      data = { isDisabled: false };
    } else if (updateType === "disable") {
      data = { isDisabled: true };
    } else {
      data = { isPublished: false };
    }
    bulkUpdateData({ id: selectedRowIds, data })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res.message);
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
      });
  }

  function refreshThumb() {
    const body = {
      videoUrl: editItem?.videoLink,
      pictureName: editItem?.videFileName
    };

    generateThumb(body)
      .unwrap()
      .then((res) => {
        setThumbs(res.data);
        console.log("sada", res);
        res.data.length && snackbar.success(res.message);
        !res.data.length && snackbar.error('Something went wrong!!');
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
      });
  }

  function updateThumbImg() {
    const body = {
      id: editItem?._id,
      body: {
        thumbs: thumbs,
        thumbImage: currentImg
      }
    }
    updateData(body)
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res.message);
        setThumb(false);
        setCurrentId("");
        setCurrentImg("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setThumb(false);
      });
  }

  return (
    <AdminLayout>
      <AdminPageFilters
        name="creative"
        setAddNew={() => setAddNew(true)}
        qty={creativeData?.data?.totalCount}
        setRegex={setRegex}
        bulkUpdate={bulkUpdate}
        setUpdateType={setUpdateType}
        setFilterType={setFilterType}
        filterType={filterType}
        sortType={sortType}
        setSortType={setSortType}
        regex={regex}
      />
      <DataGridBox
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        columns={columns}
        initialRows={rows}
        qty={creativeData?.data?.totalCount}
        rowModesModel={rowModesModel}
        selectedRowIds={selectedRowIds}
        setRowModesModel={setRowModesModel}
        onSelectionModelChange={handleSelectionChange}
      />

      {isEdit && (
        <EditCreativeModal
          item={editItem}
          open={isEdit}
          handleClose={() => setEdit(false)}
        />
      )}

      {isAddNew && (
        <AddCreativeModal
          open={isAddNew}
          handleClose={() => setAddNew(false)}
        />
      )}

      {isDelete && (
        <DeletePopup
          open={isDelete}
          handleClose={() => setDelete(false)}
          handleDelete={() => handleHardDelete()}
        />
      )}

      {isBulkDelete && (
        <DeletePopup
          open={isBulkDelete}
          handleClose={() => setBulkDelete(false)}
          handleDelete={() => handleBulkDelete()}
        >
          Are sure to delete {selectedRowIds.length} items ?"
        </DeletePopup>
      )}

      {isDeactivate && (
        <Deactivatepopup
          open={isDeactivate}
          handleClose={() => setDeactivate(false)}
          handleDelete={() => handleSoftDelete()}
        />
      )}

      {isThumb && (
        <EditModal open={isThumb} handleClose={() => setThumb(false)}>
          <h1 className="text-xl font-bold absolute top-4 left-8">
            Update Thumbnails
          </h1>
          <div className="flex flex-col max-h-[85vh] h-auto max-w-[80vw]">
            <div className="flex flex-wrap overflow-scroll gap-4 border-t border-b p-4 my-4 justify-center">
              {thumbs?.map((item) => (
                <img src={`${item}`} onClick={() => setCurrentImg(item)} alt="" className={`w-auto max-h-[30vh] ${currentImg === item && "bg-green-500 p-1"}`} />
              ))}
            </div>
            <div className="flex justify-end gap-4 w-full">
              <div>
                <ButtonV1 color="red" setActive={refreshThumb}>
                  Refresh Thumb
                </ButtonV1>
              </div>
              <div>
                <ButtonV1 color="blue" setActive={updateThumbImg}>Save</ButtonV1>
              </div>
            </div>
          </div>
        </EditModal>
      )}
    </AdminLayout>
  );
}

export default CreativePage;
