import "./loader.css"

export function Loader() {
  return (
    <div className="page">
	<div className="loadContainer">
	<div className="loadInner">
		<div className="loadCircle">
			<div className="loadCircleInner"></div>
		</div>
		<div className="loadCircle">
			<div className="loadCircleInner"></div>
		</div>
		<div className="loadCircle">
			<div className="loadCircleInner"></div>
		</div>
		<div className="loadCircle">
			<div className="loadCircleInner"></div>
		</div>
		<div className="loadCircle">
			<div className="loadCircleInner"></div>
		</div>
	</div>
</div>
</div>
  );
}
